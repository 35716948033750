import NavBar from './_partials/NavBar'
// import SideBar from './_partials/SideBar';
import TopNavBar from './referrer/TopNavBar';
import HospitalTopNavBar from './hospital/HospitalTopNavBar';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import Footer from './_partials/Footer';
import DataTable from 'react-data-table-component';
import { HandleDownload } from './DocumentsDownload';
import { ToastContainer, toast } from 'react-toastify';
import { handleAdmitReferralButtonClick } from './api';
function ReferralProfile() {
    const auth = useAuth();
    const navigate = useNavigate();
    const {ids } = useParams();
    const hospital_id = ids.split('-')[0]
    const referral_id = ids.split('-')[1]

    const getPatient_url = `https://referralapi.tkprotich.com/api/referrals/?referrer_id=${referral_id}`

  const [patientdata, setPatientData] = useState([])
  const [documents, setDocuments] = useState([])
  const serializedPatientData = encodeURIComponent(JSON.stringify(patientdata));
  useEffect(() => {
    // Assuming API endpoint for fetching patientts

    axios.get(getPatient_url, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + auth.access,
      },
    })
      .then((response) => {
        const response_data = response.data['results'][0];
        console.log(response_data);
        setPatientData(response_data);
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      })
  }, []);


  
  const MakePaymentAction = async (hospital, referral) => {
    const data = {
      'hospital': hospital,
      'referral': referral,
    };
  try {
    const response = await fetch(`https://referralapi.tkprotich.com/api/make-payout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + auth.access
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    console.log(response.status);
    if (response.status==201) {
      toast.success('Updated!')

    }
    throw new Error(res.message);
  } catch (err) {
    console.error(err);
  }
  };

  useEffect(() => {
        
    // Assuming you have the API endpoint for fetching patients
    const documentsEndpoint = `https://referralapi.tkprotich.com/api/documents/?referral_id=${referral_id}`;
    axios.get(documentsEndpoint, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + auth.access
      }
    })
    .then(response => {
const retrievedDocuments= response.data['results'];
console.log(retrievedDocuments);
setDocuments(retrievedDocuments);
    })
  .catch(function (error) {
      // handle error
      console.log(error);
      // auth.RefreshToken()
  })
  .finally(function () {
      // always executed
  });
  
  }, []);

  




//   columns
const columns = [

    {
        name:'ID',
    selector: row => row.id,
        sortable: true,
  },
    {
        name: "File",
    selector: row => row.file,
    },
    {
        name: "Name",
        selector: row => row.name,
    },       
    {
        name: "size",
        selector: row => row.size,
    },
    {
        name: 'Action',
        cell: (row) => (
            <a type="button" href={`${row.file}`} target="_blank" 
                                                    class="btn btn-outline-success waves-effect waves-light"
                                                    
                                                    >View Document
                                                    </a>
            
        ),
        ignoreRowClick: true,
        allowoverflow: true,
        button: true,
      },
    ];



    const handleReferPatient = ()=>{
        navigate(`/refer-patient/?params=${serializedPatientData}`)
    }
  return (
    
<div id="wrapper">

    {/* <!-- Topbar Start --> */}
    <NavBar/>

    {/* <!-- end Topbar --> */}

    {/* <!-- ========== Left Sidebar Start ========== --> */}

    {/* <!-- Left Sidebar End --> */}
    {/* <SideBar/> */}
    {auth.role === "referrer" ? <TopNavBar/>: auth.role==='hospital' ? <HospitalTopNavBar/>: navigate('/')}

    {/* <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== --> */}
 <div className="content-page">
        <div className="content">

            {/* <!-- Start Content--> */}
            <div className="container-fluid">

                {/* <!-- start page title -->
                
                <!-- end page title --> */}
                <ToastContainer/>
            <br/>
            
          
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-8">
                               { patientdata.status==='pending'? 
                               <button 
                               type="button" 
                               className="btn btn-success waves-effect waves-light"
                               onClick={() => handleAdmitReferralButtonClick(`${referral_id}`, 'accepted', auth)}>
                                            
                                            <i
                                                className="mdi mdi-plus-circle me-1"></i> Admit  Referral</button>
                                                :
                                                patientdata.payout_status==='unpaid'?
                                                <div className="btn-group mb-2 dropend">
                                               <button
      type="button"
      className="btn btn-primary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Action <i className="mdi mdi-chevron-left"></i>
    </button>
                                            <div className="dropdown-menu">
                                                
                                                <div className="dropdown-divider"></div>
                                                <a className="dropdown-item" href="#" onClick={() => MakePaymentAction(patientdata.accepted_by_id, referral_id)}>Pay Referrer</a>
                                                
                                            </div>
                                        </div>
        
                                                
                                                
                                                :<h3> 
                                                    {patientdata.payout_status}
                                                </h3>
                                                     }
                                </div>
                                <div className="col-md-4">
                                    <div className="text-md-end mt-3 mt-md-0">
                                        <button type="button"
                                        onClick={() => HandleDownload(`${referral_id}`, auth)}
                                            className="btn btn-success waves-effect waves-light me-1"><i
                                                className="mdi mdi-cog"></i></button>
                                        <button type="button" className="btn btn-info waves-effect waves-light"
                                           onClick={() => HandleDownload(`${referral_id}`, auth)}><i
                                                className="mdi mdi-plus-circle me-1"></i> Download Documents</button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>

                <div className="row">
                    <div className="col-lg-3 col-xl-3">
                        <div className="card text-center">
                            <div className="card-body">
                                <img src="/images/users/user-2.jpg" className="rounded-circle avatar-xl img-thumbnail" alt="profile-image" />
                
                                <h4 className="mb-1 mt-2">{patientdata.patient_name}</h4>
                                <p className="text-muted">@{patientdata.patient_name}</p>
                                <div className="text-start mt-3">
                                    <h4 className="font-13 text-uppercase">About Patient :</h4>
                                    <p className="text-muted font-13 mb-3">
                                        <span className="fw-semibold">History :</span>
                                        <span className="ms-2">{patientdata.history}</span>
                                       
                                    </p>
                                    <p className="text-muted mb-2 font-13">
                                        <span className="fw-semibold">Referred By  :</span>
                                        <span className="ms-2">{patientdata.referrer_name}</span>
                                    </p>
                
                                    <p className="text-muted mb-2 font-13"><span className="fw-semibold">Status :</span><span className="ms-2">{patientdata.status}</span></p>
                
                                    <p className="text-muted mb-2 font-13"><span className="fw-semibold">Commision :</span> <span className="ms-2">{patientdata.total_commission}%</span></p>
                
                                    <p className="text-muted mb-1 font-13"><span className="fw-semibold">Referral Code :</span> <span className="ms-2">{patientdata.referral_code}</span></p>
                                </div>
                
                                <ul className="social-list list-inline mt-3 mb-0">
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-primary text-primary"><i className="mdi mdi-facebook"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-danger text-danger"><i className="mdi mdi-google"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-info text-info"><i className="mdi mdi-twitter"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-secondary text-secondary"><i className="mdi mdi-github"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- end card --> */}
                        {/* <!-- end card--> */}
                    </div>
                    {/* <!-- end col--> */}
                
                    <div className="col-lg-9 col-xl-9">
                        <div className="card">
                            <div className="card-body">
                            {documents.length === 0 ? (
                            <p>No data available</p>
                            ) :
                            <DataTable
                                title="Referrals"
                                columns={columns}
                                data={documents}
                                pagination
                            />}
                            </div>
                        </div>
                        {/* <!-- end card--> */}
                    </div>
                    {/* <!-- end col --> */}
                </div>                
                {/* <!-- end row--> */}

            </div>
             {/* <!-- container --> */}

        </div> 
        {/* <!-- content --> */}

        {/* <!-- Footer Start --> */}
      <Footer/>
        {/* <!-- end Footer --> */}

    </div>

    {/* <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== --> */}


</div>

  );
}

export default ReferralProfile;
