import NavBar from '../_partials/NavBar'
import { useAuth } from "../../AuthProvider";
import React, { useState, useEffect } from 'react';
import HospitalTopNavBar from './HospitalTopNavBar';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Footer from '../_partials/Footer'
import {columns} from "./Columns"
import { Navigate, Link } from "react-router-dom";
export default function HospitalReferralPayouts(){
    const [referrals, setReferrals] = useState([]);
    const auth = useAuth();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
  
    
    const handleButtonClick = (row, status) => {
  setSelectedRow(row);
  // Assuming you have the referral ID and the API endpoint URL
const referralId = row['id']; // Replace with the actual referral ID
const apiUrl = 'https://referralapi.tkprotich.com/api/referrals/' + referralId + '/';

// Create the data object with the updated status
const data = {
  status: status
};

// Send the POST request
fetch(apiUrl, {
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + auth.access

  },
  body: JSON.stringify(data)
})
  .then(response => {
    if (response.ok) {
      console.log('Referral status updated successfully.');
      const updatedReferrals = referrals.filter(referral => referral.id !== referralId);
      setReferrals(updatedReferrals);

      // Handle any further actions or UI updates here
    } else {
      console.log(response.status);
    }
  })
  .catch(error => {
    console.error('An error occurred while updating referral status:', error);
    // Handle error cases here
  });
//   setShowConfirmation(true);
};
const columns = [
  {
      name: "#ID",
  selector: row => row.referrer__id,
  },
  {
      name: "Referrer Name",
  selector: row => row.referrer__name,
  },

  {
      name:'Count',
  selector: row => row.referral_count,
      sortable: true,
},
  {
      name: "Total",
      selector: row => row.total,
  },   
  {
      name: "Total Paid",
      selector: row => row.total_paid,
  },   
  {
      name: "Commission Balance",
      selector: row => row.remaining_commission,
  }, 
  {
      name: 'Action',
      cell: (row) => (
        <Link  className="btn btn-primary waves-effect waves-light rounded-pill" to={`/payouts/${row.referrer__id}`}>Make Payout</Link>

      ),
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
    },  
  ];
  
  
    //   useEffect(() => {
    //     $('#datatable-buttons').DataTable();
    // },[patients]);
    useEffect(() => {
        
      // Assuming you have the API endpoint for fetching patients
      const ReferralEndpoint = "https://referralapi.tkprotich.com/api/calculate_cumulative_commissions/";
      axios.get(ReferralEndpoint, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + auth.access
        }
      })
      .then(response => {
        console.log(response.data);
  const retrievedPatients = response.data['results'];
  console.log(retrievedPatients);
  setReferrals(retrievedPatients);
      })
    .catch(function (error) {
        // handle error
        console.log(error);
        // auth.RefreshToken()
    })
    .finally(function () {
        // always executed
    });
    
    }, []);


    return (
        
    
<div id="wrapper">

{/* <!-- Topbar Start --> */}
<NavBar/>

{/* <!-- end Topbar --> */}

{/* <!-- ========== Left Sidebar Start ========== --> */}

{/* <!-- Left Sidebar End --> */}
{/* <SideBar/> */}
<HospitalTopNavBar/>

{/* <!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== --> */}
        {/* // <!-- ============================================================== --> */}
        {/* // <!-- Start Page Content here --> */}
        {/* // <!-- ============================================================== --> */}
    
        <div className="content-page">
            <div className="content">
    
                {/* <!-- Start Content--> */}
                <div className="container-fluid">
    
                    {/* <!-- start page title --> */}
                    
                    {/* <!-- end page title --> */}
    
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                
                                <div className="card-body">
                                    <h4 className="header-title">Hospital Referral Payouts</h4>
                                    <p className="text-muted font-13 mb-4">
                                    ...
                                    </p>
                                    <div>
                                    <DataTable
                                        title="My Patients"
                                        columns={columns}
                                        data={referrals}
                                    />
                                    </div>
                                </div>
                                 {/* <!-- end card body--> */}
                            </div>
                            {/* <!-- end card --> */}
                        </div>
                        {/* <!-- end col--> */}
                    </div>
    
                </div>
                 {/* <!-- container --> */}
    
            </div> 
            {/* <!-- content --> */}
    
            {/* <!-- Footer Start --> */}
            <Footer/>
            {/* <!-- end Footer --> */}
    
        </div>
{/*     
        // <!-- ============================================================== -->
        // <!-- End Page content -->
        // <!-- ============================================================== --> */}</div>
    )
}