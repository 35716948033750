import NavBar from '../_partials/NavBar'
import SideBar from './SideBar';
import HospitalTopNavBar from './HospitalTopNavBar';
import { useAuth } from "../../AuthProvider";
import axios from 'axios';
import { useEffect, useState } from 'react';
function Dashboard() {
    const auth = useAuth()
    console.log(auth);

    const [reportdata, setReportData] = useState([])
    // api/report/
    
    const reportURL = 'https://referralapi.tkprotich.com/api/report/'


      useEffect(() => {
        // Assuming API endpoint for fetching patients
    
        axios.get(reportURL, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + auth.access,
          },
        })
          .then((response) => {
            const response_data = response.data;
            setReportData(response_data)
          })
          .catch((error) => {
            // Handle error
            console.log(error);
          })
      }, []);
  return (
    
<div id="wrapper">

    {/* <!-- Topbar Start --> */}
    <NavBar/>

    {/* <!-- end Topbar --> */}

    {/* <!-- ========== Left Sidebar Start ========== --> */}

    {/* <!-- Left Sidebar End --> */}
    {/* <SideBar/> */}
    <HospitalTopNavBar/>

    {/* <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== --> */}

<div className="content-page">
                <div className="content">

                    {/* <!-- Start Content--> */}
                    <div className="container-fluid">
                        
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex justify-content-between align-items-center">
                                    <h4 className="page-title">{auth.name}</h4>
                                    <ol className="breadcrumb m-0 page-title-right">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Wellcure</a></li>
                                        {/* <li className="breadcrumb-item"><a href="javascript: void(0);">Layouts</a></li> */}
                                        <li className="breadcrumb-item active">Horizontal</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end page title -->  */}

                        <div className="row">
                            <div className="col-12">
                                <div className="alert alert-success alert-dismissible fade show mb-3" role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    Working properly
                                </div>
                            </div>
                        </div>
        
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="avatar-sm bg-soft-success rounded">
                                                    <span className="avatar-title">
                                                        <i data-feather="shopping-bag" className="icon-dual-success icons-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-end">
                                                    <h3 className="my-1"><span data-plugin="counterup">{reportdata.referral_count}</span></h3>
                                                    <p className="text-muted mb-1 text-truncate">Referral Count</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <h6 className="text-uppercase fw-semibold mb-2">Target <span
                                                    className="float-end">49%</span></h6>
                                            <div className="progress progress-sm m-0">
                                                <div className="progress-bar bg-success" role="progressbar" aria-valuenow="49"
                                                    aria-valuemin="0" aria-valuemax="100" style={{width: '49%'}}>
                                                    <span className="visually-hidden">49% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="avatar-sm bg-soft-primary rounded">
                                                    <span className="avatar-title">
                                                        <i data-feather="box" className="icon-dual-primary icons-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-end">
                                                    <h3 className="my-1">$<span data-plugin="counterup">{reportdata.payout_sum}</span></h3>
                                                    <p className="text-muted mb-1 text-truncate">Payouts</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <h6 className="text-uppercase fw-semibold mb-2">Target <span
                                                    className="float-end">18%</span>
                                            </h6>
                                            <div className="progress progress-sm m-0">
                                                <div className="progress-bar" role="progressbar" aria-valuenow="18"
                                                    aria-valuemin="0" aria-valuemax="100" style={{width: '18%'}}>
                                                    <span className="visually-hidden">18% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="avatar-sm bg-soft-warning rounded">
                                                    <span className="avatar-title">
                                                        <i data-feather="home" className="icon-dual-warning icons-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-end">
                                                    <h3 className="my-1"><span data-plugin="counterup">${reportdata.referral_commission}</span></h3>
                                                    <p className="text-muted mb-1 text-truncate">Referral Commission</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <h6 className="text-uppercase fw-semibold mb-2">Target <span
                                                    className="float-end">74%</span>
                                            </h6>
                                            <div className="progress progress-sm m-0">
                                                <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="74"
                                                    aria-valuemin="0" aria-valuemax="100" style={{width: '74%'}}>
                                                    <span className="visually-hidden">74% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="avatar-sm bg-soft-warning rounded">
                                                    <span className="avatar-title">
                                                        <i data-feather="home" className="icon-dual-warning icons-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-end">
                                                    <h3 className="my-1"><span data-plugin="counterup">${reportdata.unpaid_commission}</span></h3>
                                                    <p className="text-muted mb-1 text-truncate">Unpaid Referral Commission</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <h6 className="text-uppercase fw-semibold mb-2">Target <span
                                                    className="float-end">0%</span>
                                            </h6>
                                            <div className="progress progress-sm m-0">
                                                <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="74"
                                                    aria-valuemin="0" aria-valuemax="100" style={{width: '74%'}}>
                                                    <span className="visually-hidden">74% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        {/* <!-- end row --> */}
        
                        {/* <!-- end row --> */}
        
                        
                        {/* <!-- end row --> */}
        
                        {/* <!-- end row --> */}
        
        
                       
                        {/* <!-- end row --> */}
                        
                    </div> 
                    {/* <!-- container --> */}

                </div> 
                {/* <!-- content --> */}

                {/* <!-- Footer Start --> */}
                <footer className="footer text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <script>document.write(new Date().getFullYear())</script> &copy; Upbit theme by <a href="">Coderthemes</a> 
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- end Footer --> */}

            </div>
    {/* <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== --> */}


</div>

  );
}

export default Dashboard;
