import NavBar from './_partials/NavBar'
// import SideBar from './_partials/SideBar';
import { TopNavBar as TBN } from './_partials/TopNavBar';
import TopNavBar from './referrer/TopNavBar';
import HospitalTopNavBar from './hospital/HospitalTopNavBar';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import Footer from './_partials/Footer';
import DataTable from 'react-data-table-component';
import { HandleDownload } from './DocumentsDownload';
function FAQ() {
    const auth = useAuth();
    const navigate = useNavigate();
    const { patient_id } = useParams();
    const getPatient_url = `https://referralapi.tkprotich.com/api/patients/${patient_id}/`

  const [patientdata, setPatientData] = useState([])
  const [referrals, setReferrals] = useState([])
   
  useEffect(() => {
    // Assuming API endpoint for fetching patients

    axios.get(getPatient_url, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + auth.access,
      },
    })
      .then((response) => {
        const response_data = response.data;
        setPatientData(response_data);
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      })
  }, []);


  useEffect(() => {
        
    // Assuming you have the API endpoint for fetching patients
    const ReferralEndpoint = `https://referralapi.tkprotich.com/api/referrals/?patient_id=${patient_id}`;
    axios.get(ReferralEndpoint, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + auth.access
      }
    })
    .then(response => {
const retrievedReferrals = Object.values(response.data);
console.log(retrievedReferrals);
setReferrals(retrievedReferrals);
    })
  .catch(function (error) {
      // handle error
      console.log(error);
      // auth.RefreshToken()
  })
  .finally(function () {
      // always executed
  });
  
  }, []);

  




//   columns
const columns = [

    {
        name:'ID',
    selector: row => row.id,
        sortable: true,
  },
    {
        name: "Name",
    selector: row => row.patient_name,
    },
    {
        name: "Referrer",
        selector: row => row.referrer_name,
    },       
    {
        name: "Diagnosis",
        selector: row => row.current_diagnosis,
    },
    {
        name: "Date Referral",
        selector: row => row.referral_date,
    },
    {
        name: "Status",
        selector: row => row.status,
    },
    {
        name: 'Action',
        cell: (row) => (
            <button type="button"
                                                    class="btn btn-outline-success waves-effect waves-light"
                                                    onClick={() => HandleDownload(`${row.id}`, auth)}
                                                    >Download-{row.documents_count}
                                                    </button>
            
        ),
        ignoreRowClick: true,
        allowoverflow: true,
        button: true,
      },
    ];

  return (
    
<div id="wrapper">

    {/* <!-- Topbar Start --> */}
    <NavBar/>

    {/* <!-- end Topbar --> */}

    {/* <!-- ========== Left Sidebar Start ========== --> */}

    {/* <!-- Left Sidebar End --> */}
    {/* <SideBar/> */}
    {auth.role === "referrer" ? <TopNavBar/>: auth.role==='hospital' ? <HospitalTopNavBar/>: <TopNavBar/>}

    {/* <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== --> */}
 <div class="content-page">
        <div class="content">

            {/* <!-- Start Content--> */}
            <div class="container-fluid">

                {/* <!-- start page title --> */}
                
                {/* <!-- end page title --> */}


                <div class="row">
                    <div class="col-12">
                        <div class="text-center">
                            <i class="h1 mdi mdi-comment-multiple-outline text-muted"></i>
                            <h3 class="mb-3">Frequently Asked Questions</h3>
                            <p class="text-muted"> In the Gap for the Patient</p>

                            <button type="button" class="btn btn-success waves-effect waves-light mt-2 me-1"><i
                                    class="mdi mdi-email-outline me-1"></i> Email us your question</button>
                            <button type="button" class="btn btn-primary waves-effect waves-light mt-2"><i
                                    class="mdi mdi-twitter me-1"></i> Send us a tweet</button>

                        </div>
                    </div>
                    {/* <!-- end col --> */}
                </div>
                {/* <!-- end row --> */}


                <div class="row pt-5">
                    <div class="col-lg-5 offset-lg-1">
                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question" data-wow-delay=".1s">What is a referral?</h4>
                            <p class="faq-answer mb-4">A referral is a process where a healthcare provider sends a patient to another healthcare provider or specialist for further evaluation, treatment, or services.
                            </p>
                        </div>

                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question">How can our hospital receive referrals?</h4>
                            <p class="faq-answer mb-4">To receive referrals, your hospital needs to establish partnerships with referrers such as primary care physicians, clinics, or other healthcare providers. You can also promote your hospital's services to attract potential referrers.</p>
                        </div>

                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question">
How do we handle incoming referrals?</h4>
                            <p class="faq-answer mb-4">When you receive a referral, you need to review the referral information, assess the patient's needs, and assign appropriate healthcare professionals. Ensure effective communication with referrers to gather any additional details required for the referral.
                            </p>
                        </div>

                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question" data-wow-delay=".1s">What information should be included in a referral?</h4>
                            <p class="faq-answer mb-4">A referral should include essential details such as patient demographics, medical history, reason for referral, desired services, and any supporting documentation like test results or imaging reports.
                            </p>
                        </div>
                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question" data-wow-delay=".1s">How do we track and manage referrals?</h4>
                            <p class="faq-answer mb-4">Implement a referral management system that allows you to track the progress of referrals, assign tasks, and communicate with referrers and other healthcare professionals involved. This helps ensure a seamless referral process and timely follow-up.
                            </p>
                        </div>
                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question" data-wow-delay=".1s">What if we need to decline a referral?</h4>
                            <p class="faq-answer mb-4">In certain cases, your hospital may need to decline a referral due to capacity limitations or if the requested service is not within your scope of expertise. It is important to communicate promptly with the referrer, providing clear reasons for the decline and alternative options if available.
                            </p>
                        </div>

                    </div>
                    {/* <!--/col-md-5 --> */}

                    <div class="col-lg-5">
                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question">When should I make a referral?</h4>
                            <p class="faq-answer mb-4">You should consider making a referral when a patient's condition requires specialized care, diagnostic tests, or treatments that are beyond your scope of practice or available resources.
                            </p>
                        </div>

                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question">How do I initiate a referral?</h4>
                            <p class="faq-answer mb-4">
Initiate a referral by gathering relevant patient information, including medical history, current condition, and any supporting documentation. Contact the receiving healthcare provider or their designated referral coordinator to discuss the referral and provide the necessary information.</p>
                        </div>

                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question">What information should I include in a referral?</h4>
                            <p class="faq-answer mb-4">
Provide comprehensive patient information, including demographics, relevant medical history, reason for referral, specific services or specialists requested, and any supporting documents such as test results or imaging reports.
                            </p>
                        </div>

                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question">How can I ensure effective communication with the receiving provider?</h4>
                            <p class="faq-answer mb-4">Maintain open lines of communication with the receiving provider or their referral coordinator. Clearly convey the reason for referral, patient details, and any specific concerns or expectations. Be responsive to any requests for additional information or updates.
                            </p>
                        </div>
                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question">What should I do if a referral is declined?
</h4>
                            <p class="faq-answer mb-4">If a referral is declined, communicate with the receiving provider to understand the reasons for the decline. Discuss alternative options or seek guidance from other specialists or healthcare professionals to ensure appropriate care for the patient. </p>
                        </div>
                        {/* <!-- Question/Answer --> */}
                        <div>
                            <div class="faq-question-q-box">Q.</div>
                            <h4 class="faq-question">How can I track the progress of referrals?</h4>
                            <p class="faq-answer mb-4">
                            Maintain a record of referrals made, including dates, receiving providers, and any follow-up actions. Follow up with the receiving provider to obtain feedback or updates on the patient's care. Consider using a referral management system if available.
                            </p>
                        </div>

                    </div>
                    {/* <!--/col-md-5--> */}
                </div>
                {/* <!-- end row --> */}


            </div> 
            {/* <!-- container --> */}

        </div> 
        {/* <!-- content --> */}

    {/*  */}
    <Footer/>

    </div>

    {/* <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== --> */}


</div>

  );
}

export default FAQ;
