import { useState, useEffect, useRef} from "react";
import NavBar from '../_partials/NavBar'
import HospitalTopNavBar from './HospitalTopNavBar';
import { useAuth } from "../../AuthProvider";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from 'react-select';
function MakeReferrerPayout() {
    const [referrals, setReferrals] = useState([]);
    const selectReferralRef = useRef();
    const auth = useAuth();
    const { referrerId } = useParams();
    const navigate = useNavigate();
    const [input, setInput] = useState({
      referrer: referrerId,
      hospital: auth.roleId,
      transaction_id: '',
      referrals: [],
      description: '',
      amount: '',
      payment_method: '',
    });
  
    const MakePaymentAction = async (data) => {
    
    try {
      const response = await fetch(`https://referralapi.tkprotich.com/api/make-payout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + auth.access
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      console.log(response.status);
      if (response.status==201) {
        console.log('response');
        navigate("/payouts");
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };
    const handleSubmitEvent = (e) => {
      e.preventDefault();
        if (input.referrals !== false &&  input.hospital !== "") {
            MakePaymentAction(input);
            return;
        }
        alert("pleae provide a valid input");
        };
    const handleInput = (e) => {
    
    const { name, value } = e.target;
            setInput((prev) => ({
                    ...prev,
                    [name]: value,
                    }));
                };

    
                useEffect(() => {
        
                    // Assuming you have the API endpoint for fetching patients
                    const ReferralEndpoint = "https://referralapi.tkprotich.com/api/referrals/?status=accepted";
                    axios.get(ReferralEndpoint, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + auth.access
                      }
                    })
                    .then(response => response.data)
                    .then(data => {
                        console.log(data);
                        // Assuming the API response returns an array of hospital names
                        const optionItems = data.map((d) => {
                        return { value: d.id, label: `${d.patient_name} Cost:${d.total_commission}` };
                        }); 
                        setReferrals(optionItems)
                    })
                  .catch(function (error) {
                      // handle error
                      console.log(error);
                      // auth.RefreshToken()
                  })
                  .finally(function () {
                      // always executed
                  });
                  
                  }, []);
    // Handlers
    const handleReferralInput = (e) => {
        // let amount = 0
        
        // e.map(h => amount = amount + Number(h.label.split(':')[1]));
        
        if(e){
        setInput(prevInput => ({
            ...prevInput,
            ['referrals']: e.map(h => h.value)
        }));
    }
                };


  return (
    
<div id="wrapper">

    {/* <!-- Topbar Start --> */}
    <NavBar/>

    {/* <!-- end Topbar --> */}

    {/* <!-- ========== Left Sidebar Start ========== --> */}

    {/* <!-- Left Sidebar End --> */}
    {/* <SideBar/> */}
    <HospitalTopNavBar/>

    {/* <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== --> */}

<div className="content-page">
                <div className="content">

                    {/* <!-- Start Content--> */}
                    <div className="container-fluid">
                        
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex justify-content-between align-items-center">
                                    <h4 className="page-title">Conntecting Patient to Services</h4>
                                    <ol className="breadcrumb m-0 page-title-right">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Somalia</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end page title -->  */}
                        

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="header-title">Make Payment</h4>
                                        <p className="sub-header">
                                           Pay commission to referrer for patient referral 
                                        </p>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                
                                            <form onSubmit={handleSubmitEvent}>
                                                <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="transaction_id" className="form-label">
                                                Transaction ID
                                                </label>
                                                <input
                                                type="text"
                                                id="transaction_id"
                                                name="transaction_id"
                                                className="form-control"
                                                placeholder="Transaction ID"
                                                value={input.transaction_id}
                                                onChange={handleInput}
                                                />
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="description" className="form-label">
                                                Description
                                                </label>
                                                <textarea
                                                id="description"
                                                name="description"
                                                className="form-control"
                                                placeholder="Description"
                                                value={input.description}
                                                onChange={handleInput}
                                                ></textarea>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                    <label htmlFor="referral" className="form-label">Select Referral</label>
                                                    <Select
                                                    id='referral'
                                                    onChange={handleReferralInput}
                                                    ref={selectReferralRef}
                                                    options={referrals}
                                                    isMulti
                                                    />
                                                    </div>

                                            

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="payment_method" className="form-label">
                                                Payment Method
                                                </label>
                                                <input
                                                type="text"
                                                id="payment_method"
                                                name="payment_method"
                                                className="form-control"
                                                placeholder="Payment Method"
                                                value={input.payment_method}
                                                onChange={handleInput}
                                                />
                                            </div>
                                            </div>

                                           <div className="row">
                                                <div className="col-md-12 mb-3 d-flex justify-content-end">
                                                    <button className="btn btn-primary" type="submit">Submit</button>
                                                </div>
                                                </div>
                                            </form>
                                            </div> 
                                            {/* <!-- end col --> */}
                                        </div>
                                        {/* <!-- end row--> */}

                                    </div>
                                    {/* <!-- end card-body --> */}
                                </div> 
                                {/* <!-- end card --> */}
                            </div>
                            {/* <!-- end col --> */}
                        </div>
                        {/* <!-- end row --> */}
                        
                    </div> 
                    {/* <!-- container --> */}

                </div> 
                {/* <!-- content --> */}

                {/* <!-- Footer Start --> */}
                <footer className="footer text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <script>document.write(new Date().getFullYear())</script> &copy; Upbit theme by <a href="">Coderthemes</a> 
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- end Footer --> */}

            </div>
    {/* <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== --> */}


</div>

  );
}

export default MakeReferrerPayout;
