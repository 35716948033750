import React from 'react';
import axios from 'axios';

export const HandleDownload = (referral_id, auth) => {
    const FileDownload = require('js-file-download');
  axios({
    url: `https://referralapi.tkprotich.com/api/documents/${referral_id}/`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + auth.access,
    },
    responseType: 'blob', // Important
  }).then((response) => {
    console.log(response);
      FileDownload(response.data, 'report.zip');
  });


}