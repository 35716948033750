
import NavBar from '../_partials/NavBar';
import TopNavBar from './TopNavBar';
import { useAuth } from "../../AuthProvider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from '../_partials/Alert';
export default function RegisterPatient() {
    const auth = useAuth();
    const [input, setInput] = useState({
        name: '',
        phone_number: '',
        address: '',
        email: '',
        date_of_birth: '',
        gender: '',
        referral_code: '',
        referrer: auth.roleId,
        hospital: null,
        note: '',
        history: '',
        current_diagnosis: ''
    });
    
    const navigate = useNavigate();
    const RegisterOrgAction = async (data) => {
        const response = await fetch(`https://referralapi.tkprotich.com/api/register-patient/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${auth.access}`, // Replace `token` with your actual token value
        },
        body: JSON.stringify(data),
        }).then(
            response=>{
                if(response.statusText=='Unauthorized'){
                    auth.RefreshToken()
                    // console.log('Unauthorized???');
                    // console.log(auth.access);
                    // console.log(response.statusText);
                }
                if(!response.ok){
                    console.log(response.statusText);
                    console.log(response.status);
                    throw new Error('Network response was not ok');
                }

            }
        )
        
        .then(newUserData => {
            // Process the newly created user data
            setAlertMessage('Saved!')
            console.log(newUserData);
            setInput({
                name: '',
                phone_number: '',
                address: '',
                email: '',
                date_of_birth: '',
                gender: '',
                referral_code: '',
                referrer: auth.roleId,
                hospital: null,
                note: '',
                history: '',
                current_diagnosis: ''
            })
        })
        .catch(error => {
            console.log('error..................');
            console.log(error);
            setAlertMessage(error.toString())
        });
    };
    const handleSubmitEvent = (e) => {
        e.preventDefault();
        if (input.phone_number !== "") {
            RegisterOrgAction(input);
            return;
        }
        console.log('Please provide a valid input');

        setAlertMessage('Please provide a valid input');
        };
    const [alertMessage, setAlertMessage] = useState('');
    const handleInput = (e) => {
    
    
    const { name, value } = e.target;
            setInput((prev) => ({
                    ...prev,
                    [name]: value,
                    }));
                };
    return (
    
<div id="wrapper">

    {/* <!-- Topbar Start --> */}
    <NavBar/>

    {/* <!-- end Topbar --> */}

    {/* <!-- ========== Left Sidebar Start ========== --> */}

    {/* <!-- Left Sidebar End --> */}
    {/* <SideBar/> */}
    <TopNavBar/>

    {/* <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== --> */}

<div className="content-page">
                <div className="content">

                    {/* <!-- Start Content--> */}
                    <div className="container-fluid">
                        
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex justify-content-between align-items-center">
                                    <h4 className="page-title">Conntecting Patient to Services</h4>
                                    <ol className="breadcrumb m-0 page-title-right">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Somalia</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end page title -->  */}
                        {alertMessage && <Alert message={alertMessage} />}

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="header-title">Input Types</h4>
                                        <p className="sub-header">
                                            Become a referrer and earn commission/ 
                                            Register as Hospital for patient referral 
                                        </p>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <form onSubmit={handleSubmitEvent}>
                                                    <div className='row'>
                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="name" className="form-label">Name</label>
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        value={input.name}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="phone_number" className="form-label">Phone Number</label>
                                                    <input
                                                        type="text"
                                                        id="phone_number"
                                                        name="phone_number"
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                        value={input.phone_number}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="address" className="form-label">Address</label>
                                                    <input
                                                        type="text"
                                                        id="address"
                                                        name="address"
                                                        className="form-control"
                                                        placeholder="Address"
                                                        value={input.address}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Email"
                                                        value={input.email}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="date_of_birth" className="form-label">Date of Birth</label>
                                                    <input
                                                        type="date"
                                                        id="date_of_birth"
                                                        name="date_of_birth"
                                                        className="form-control"
                                                        placeholder="Date of Birth"
                                                        value={input.date_of_birth}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="gender" className="form-label">Gender</label>
                                                    <input
                                                        type="text"
                                                        id="gender"
                                                        name="gender"
                                                        className="form-control"
                                                        placeholder="Gender"
                                                        value={input.gender}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="government_id" className="form-label">Government ID</label>
                                                    <input
                                                        type="text"
                                                        id="government_id"
                                                        name="government_id"
                                                        className="form-control"
                                                        placeholder="Government ID"
                                                        value={input.government_id}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="history" className="form-label">History</label>
                                                    <textarea
                                                        id="history"
                                                        name="history"
                                                        className="form-control"
                                                        placeholder="History"
                                                        value={input.history}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                        
                                                                <div className="row">
                                                                <div className="col-md-12 mb-3 d-flex justify-content-end">
                                                                <button className="btn btn-primary" type="submit">Submit</button>
                                                                </div>
                                                                </div>
                                                        
                                                    </div>
                                                </form>
                                            </div> 
                                            {/* <!-- end col --> */}
                                        </div>
                                        {/* <!-- end row--> */}

                                    </div>
                                    {/* <!-- end card-body --> */}
                                </div> 
                                {/* <!-- end card --> */}
                            </div>
                            {/* <!-- end col --> */}
                        </div>
                        {/* <!-- end row --> */}
                        
                    </div> 
                    {/* <!-- container --> */}

                </div> 
                {/* <!-- content --> */}

                {/* <!-- Footer Start --> */}
                <footer className="footer text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <script>document.write(new Date().getFullYear())</script> &copy; Upbit theme by <a href="">Coderthemes</a> 
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- end Footer --> */}

            </div>
    {/* <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== --> */}


</div>

    );
}