import NavBar from '../_partials/NavBar'
import { useAuth } from "../../AuthProvider";
import React, { useState, useEffect } from 'react';
import HospitalTopNavBar from './HospitalTopNavBar';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Footer from '../_partials/Footer'
import {columns} from "./Columns"
import { Navigate } from "react-router-dom";
import Notification from '../Notification';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function HospitalReferralOrders(){
    const [referrals, setReferrals] = useState([]);
    const [notificationText, setnotificationText] = useState('');
    const [showNotification, setshowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState('success');
    const auth = useAuth();

const columns = [

  {
      name:'ID',
  selector: row => row.id,
      sortable: true,
},
  {
      name: "Name",
  selector: row => row.patient_name,
  },
  {
      name: "Referrer",
      selector: row => row.referrer_name,
  },       
  {
      name: "Diagnosis",
      selector: row => row.current_diagnosis,
  },
  {
      name: "Date Referral",
      selector: row => row.referral_date,
  },
  {
      name: "Status",
      selector: row => row.status,
  },
  {
      name: 'Action',
      cell: (row) => (
        <a className="dropdown-item" href={`/referral-orders/${row.accepted_by_id?row.accepted_by_id:''}-${row.id?row.id:''}`}>View Referral</a>
        
        
      ),
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
    },
  ];
  
  
    //   useEffect(() => {
    //     $('#datatable-buttons').DataTable();
    // },[patients]);
    useEffect(() => {
        
      // Assuming you have the API endpoint for fetching patients
      const ReferralEndpoint = "https://referralapi.tkprotich.com/api/referrals/?status=pending";
      axios.get(ReferralEndpoint, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + auth.access
        }
      })
      .then(response => {
        console.log(response);
  const retrievedPatients =response.data;
  console.log(retrievedPatients);
  
  setReferrals(retrievedPatients['results']);
      })
    .catch(function (error) {
        // handle error
        console.log(error);
        // auth.RefreshToken()
    })
    .finally(function () {
        // always executed
    });
    
    }, []);


    return (
        
    
<div id="wrapper">

{/* <!-- Topbar Start --> */}
<NavBar/>

{/* <!-- end Topbar --> */}

{/* <!-- ========== Left Sidebar Start ========== --> */}

{/* <!-- Left Sidebar End --> */}
{/* <SideBar/> */}
<HospitalTopNavBar/>

{/* <!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== --> */}
        {/* // <!-- ============================================================== --> */}
        {/* // <!-- Start Page Content here --> */}
        {/* // <!-- ============================================================== --> */}
    
        <div className="content-page">
            <div className="content">
    
                {/* <!-- Start Content--> */}
                <div className="container-fluid">
                <br/>
                    {/* <!-- Notifaction area --> */}
                    <ToastContainer />
                    
                    {/* <!-- end Notifaction area--> */}
    
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                
                                <div className="card-body">
                                    <h4 className="header-title">Referral Orders</h4>
                                    <p className="text-muted font-13 mb-4">
                                    ...
                                    </p>
                                    <div>
                                    <DataTable
                                        title="My Patients"
                                        columns={columns}
                                        data={referrals}
                                    />
                                    </div>
                                </div>
                                 {/* <!-- end card body--> */}
                            </div>
                            {/* <!-- end card --> */}
                        </div>
                        {/* <!-- end col--> */}
                    </div>
    
                </div>
                 {/* <!-- container --> */}
    
            </div> 
            {/* <!-- content --> */}
    
            {/* <!-- Footer Start --> */}
            <Footer/>
            {/* <!-- end Footer --> */}
    
        </div>
{/*     
        // <!-- ============================================================== -->
        // <!-- End Page content -->
        // <!-- ============================================================== --> */}</div>
    )
}