// import { Feather } from 'react-feather';

export default function HospitalTopNavBar() {
    return (
        <div className="topnav">
        <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">

                <div className="collapse navbar-collapse" id="topnav-menu-content">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <i data-feather="home" className="me-1"></i> Dashboards
                            </a>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-ui" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i data-feather="briefcase" className="me-1"></i> Hospital Info <div className="arrow-down"></div>
                            </a>

                            <div className="dropdown-menu mega-dropdown-menu dropdown-mega-menu-xl" aria-labelledby="topnav-ui">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div>
                                            <a href="/services" className="dropdown-item">Services</a>
                                            <a href="/commision" className="dropdown-item">Commision per Diagnostics</a>
                                            {/* <a href="/analysis" className="dropdown-item">Analysis</a> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-ui" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i data-feather="briefcase" className="me-1"></i> Referrals <div className="arrow-down"></div>
                            </a>

                            <div className="dropdown-menu mega-dropdown-menu dropdown-mega-menu-xl" aria-labelledby="topnav-ui">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div>
                                            <a href="/referralorders" className="dropdown-item">Referral Orders</a>
                                            {/* <a href="/payouts" className="dropdown-item">Payouts</a> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-ui" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i data-feather="briefcase" className="me-1"></i> Patients <div className="arrow-down"></div>
                            </a>

                            <div className="dropdown-menu mega-dropdown-menu dropdown-mega-menu-xl" aria-labelledby="topnav-ui">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div>
                                            <a href="/admitted" className="dropdown-item">Admitted Patients</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </li>


                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-apps" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i data-feather="grid" className="me-1"></i> Support <div className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-apps">
                                <a href="/faq" className="dropdown-item">FAQ</a>
                                {/* <a href="email-inbox.html" className="dropdown-item">Meet with User</a>
                                <a href="email-compose.html" className="dropdown-item">Commision Issue</a> */}
                            </div>
                        </li>
                    </ul> 
                    {/* <!-- end navbar--> */}
                </div> 
                {/* <!-- end .collapsed--> */}
            </nav>
        </div> 
        {/* <!-- end container-fluid --> */}
    </div> 
    // <!-- end topnav-->


)}