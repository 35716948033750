import NavBar from '../_partials/NavBar'
import { useAuth } from "../../AuthProvider";
import React, { useState, useEffect } from 'react';
import HospitalTopNavBar from './HospitalTopNavBar';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Footer from '../_partials/Footer'
import {columns} from "./Columns"
import { Navigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
export default function HospitalReferralAccepted(){
    const [referrals, setReferrals] = useState([]);
    const auth = useAuth();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
  
    const confirmAction = () => {
      // Reset the state
      setSelectedRow(null);
      setShowConfirmation(false);
    };
  
  const cancelAction = () => {
      // Cancel the action
      setSelectedRow(null);
      setShowConfirmation(false);
    };
  
    const getAcceptedReferral =()=>{
      // Assuming you have the API endpoint for fetching patients
      const ReferralEndpoint = "https://referralapi.tkprotich.com/api/referrals/?status=accepted";
      axios.get(ReferralEndpoint, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + auth.access
        }
      })
      .then(response => {
    const retrievedPatients = response.data['results'];
    setReferrals(retrievedPatients);
      })
    .catch(function (error) {
        // handle error
        console.log(error);
        // auth.RefreshToken()
    })
    .finally(function () {
        // always executed
    });
    }

    

  
    const MakePaymentAction = async (hospital, referral) => {
      const data = {
        'hospital': hospital,
        'referral': referral,
      };
    try {
      const response = await fetch(`https://referralapi.tkprotich.com/api/make-payout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + auth.access
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      console.log(response.status);
      if (response.status==201) {
        toast.success('Updated!')
        getAcceptedReferral()

      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
    };
    
const columns = [

  {
      name:'ID',
  selector: row => row.id,
      sortable: true,
},
  {
      name: "Name",
  selector: row => row.patient_name,
  },
  {
      name: "Referrer",
      selector: row => row.referrer_name,
  },       
  {
      name: "Diagnosis",
      selector: row => row.current_diagnosis,
  },
  {
      name: "Date Referral",
      selector: row => row.referral_date,
  },
  {
      name: "Status",
      selector: row => row.status,
  },
  {
      name: "Payment Status",
      selector: row => row.payout_status,
  },
  
  {
    name: 'Action',
    cell: (row) => (
      <a className="dropdown-item" href={`/referral-orders/${row.accepted_by_id?row.accepted_by_id:''}-${row.id?row.id:''}`}>View Referral</a>
      
      
    ),
    ignoreRowClick: true,
    allowoverflow: true,
    button: true,
  },
  {
      name: 'Payment Action',
      cell: (row) => (<div className="btn-group mb-2 dropend">
                                               <button
      type="button"
      className="btn btn-primary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Action <i className="mdi mdi-chevron-left"></i>
    </button>
                                            <div className="dropdown-menu">
                                                
                                                <div className="dropdown-divider"></div>
                                                <a className="dropdown-item" href="#" onClick={() => MakePaymentAction(row.accepted_by_id, row.id)}>Pay Referrer</a>
                                                
                                            </div>
                                        </div>
        
      ),
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
    },
  ]; 
    //   useEffect(() => {
    //     $('#datatable-buttons').DataTable();
    // },[patients]);
    useEffect(() => {
      getAcceptedReferral()
    
    
    }, []);


    return (
        
    
<div id="wrapper">

{/* <!-- Topbar Start --> */}
<NavBar/>

{/* <!-- end Topbar --> */}

{/* <!-- ========== Left Sidebar Start ========== --> */}

{/* <!-- Left Sidebar End --> */}
{/* <SideBar/> */}
<HospitalTopNavBar/>

{/* <!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== --> */}
        {/* // <!-- ============================================================== --> */}
        {/* // <!-- Start Page Content here --> */}
        {/* // <!-- ============================================================== --> */}
    
        <div className="content-page">
            <div className="content">
    
                {/* <!-- Start Content--> */}
                <div className="container-fluid">
    
                    {/* <!-- start page title --> */}
                    
                    {/* <!-- end page title --> */}
    
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                
                                <div className="card-body">
                                    <h4 className="header-title">Admitted Patients</h4>
                                    <ToastContainer/>
                                    <p className="text-muted font-13 mb-4">
                                    ...
                                    </p>
                                    <div>
                                    <DataTable
                                        title="My Patients"
                                        columns={columns}
                                        data={referrals}
                                    />
                                    {showConfirmation && (
                                      
                                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static"
                                data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel"
                                aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
                                            terry richardson ad squid. 3 wolf moon officia aute,
                                            non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                                            laborum eiusmod. Brunch 3 wolf moon
                                            tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                                            assumenda shoreditch et.
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary"
                                                data-bs-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary">Understood</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                    )}
                                    </div>
                                </div>
                                 {/* <!-- end card body--> */}
                            </div>
                            {/* <!-- end card --> */}
                        </div>
                        {/* <!-- end col--> */}
                    </div>
    
                </div>
                 {/* <!-- container --> */}
    
            </div> 
            {/* <!-- content --> */}
    
            {/* <!-- Footer Start --> */}
            <Footer/>
            {/* <!-- end Footer --> */}
    
        </div>
{/*     
        // <!-- ============================================================== -->
        // <!-- End Page content -->
        // <!-- ============================================================== --> */}</div>
    )
}