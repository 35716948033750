import { useState } from "react";
import { useAuth } from "../AuthProvider";
import { useNavigate } from "react-router-dom";
import Footer from '../components/_partials/Footer'
import validator from 'validator' 

import { ToastContainer, toast } from 'react-toastify';
export default function Signup() {
    const auth = useAuth();
    const [input, setInput] = useState({
        first_name: "",
        last_name: "",
        name: "",
        email: "",
        role: "",
        contact: "",
        password: "",
    });
  
    const navigate = useNavigate();
    const RegisterOrgAction = async (data) => {
    
    try {
      const response = await fetch(`https://referralapi.tkprotich.com/api/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      console.log(res);
      console.log(res.username);
      if (res.username) {
        auth.loginAction({username:res.username, password: res.password});
        return;
      }
      throw new Error(res);
    } catch (err) {
        console.log();
        console.log(typeof(err));
      toast.error(err.message)
    }
  };
    const handleSubmitEvent = (e) => {
      e.preventDefault();
      if (input.contact !== "" && input.password !== ""  && input.role !== ""  && input.first_name !== "" && input.email !== "") {
        RegisterOrgAction(input);
        return;
      }
      toast.error("please provide all valid inputs");
    };
  const handleInput = (e) => {
    
    const { name, value } = e.target;
    
    setInput((prev) => ({
        ...prev,
        [name]: value,
      }));
  };
  return (
    <div className="loading authentication-bg authentication-bg-pattern">

    <div className="account-pages mt-5 mb-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6 col-xl-4">
                    <div className="card bg-pattern">

                        <div className="card-body p-4">

                            <div className="text-center w-75 m-auto">
                                <div className="auth-logo">
                                    <a href="index.html" className="logo logo-dark text-center">
                                        <span className="logo-lg">
                                            <img src="/images/logo-dark.png" alt="" height="28"/>
                                        </span>
                                    </a>

                                    <a href="index.html" className="logo logo-light text-center">
                                        <span className="logo-lg">
                                            <img src="/images/logo-light.png" alt="" height="28"/>
                                        </span>
                                    </a>
                                </div>
                                <ToastContainer/>
                                <p className="text-muted mb-4 mt-3">Enter your email address/username/phone number and password to access admin
                                    panel.</p>
        <p> <a href="auth-recoverpw.html" className="text-white-50 ms-1">Forgot your password?</a></p>
        <p className="">Have an account? <a href="/login"
                className="text-decoration-underline"><b>Login</b></a></p>
                            </div>

                            <form onSubmit={handleSubmitEvent}>

                                <div className="mb-3">
                                    <label for="role" className="form-label">Choose Role</label>
                                    <select  class="form-select" type="text" name="role" id="role" required
                                        placeholder="Enter your Role" 
                                        onChange={handleInput}>
                                        <option>.....</option>
                                        <option value={'hospital'}> Hospital</option>
                                        <option value={'referrer'}>Referrer</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label for="contact" className="form-label">Phone Number</label>
                                    <input className="form-control" type="text" name="contact" id="contact" required=""
                                        placeholder="Enter your phone number" 
                                        onChange={handleInput}/>
                                </div>
                                <div className="mb-3">
                                    <label for="first_name" className="form-label">First Name</label>
                                    <input className="form-control" type="text" name="first_name" id="first_name" required=""
                                        placeholder="Enter your First Name" 
                                        onChange={handleInput}/>
                                </div>
                                <div className="mb-3">
                                    <label for="last_name" className="form-label">Other Names</label>
                                    <input className="form-control" type="text" name="last_name" id="last_name" required=""
                                        placeholder="Enter your Other names" 
                                        onChange={handleInput}/>
                                </div>
                                <div className="mb-3">
                                    <label for="name" className="form-label">Organization Name</label>
                                    <input className="form-control" type="text" name="name" id="name" required=""
                                        placeholder="Enter your Organization Name" 
                                        onChange={handleInput}/>
                                </div>
                                <div className="mb-3">
                                    <label for="email" className="form-label">Personal Email</label>
                                    <input className="form-control" type="email" name="email" id="email" required=""
                                        placeholder="Enter your Email" 
                                        onChange={handleInput}/>
                                </div>

                                <div className="mb-3">
                                    <label for="password" className="form-label">Password</label>
                                    <div className="input-group input-group-merge">
                                        <input type="password" id="password" className="form-control"
                                            placeholder="Enter your password" name="password"
                                            onChange={handleInput}/>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="checkbox-signin" checked/>
                                        <label className="form-check-label" for="checkbox-signin">Remember me</label>
                                    </div>
                                </div>

                                <div className="text-center d-grid">
                                    <button className="btn btn-primary" type="submit"> Sign Up </button>
                                </div>

                            </form>
                            

                            <div className="row mt-3">
                                <div className="col-12 text-center">
                                    <p> <a href="auth-recoverpw.html">Forgot your password?</a></p>
                                    <p className="text-white-50">Don't have an account? <a href="auth-register.html"
                                            className="text-white ms-1 text-decoration-underline"><b>Sign Up</b></a></p>
                                </div> 
                                {/* <!-- end col --> */}
                            </div>

                            <div className="text-center">
                                <h5 className="mt-3 text-muted">Sign in with</h5>
                                <ul className="social-list list-inline mt-3 mb-0">
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);"
                                            className="social-list-item border-primary text-primary"><i
                                                className="mdi mdi-facebook"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);"
                                            className="social-list-item border-danger text-danger"><i
                                                className="mdi mdi-google"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-info text-info"><i
                                                className="mdi mdi-twitter"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);"
                                            className="social-list-item border-secondary text-secondary"><i
                                                className="mdi mdi-github"></i></a>
                                    </li>
                                </ul>
                            </div>

                        </div> 
                        {/* <!-- end card-body --> */}
                    </div>
                    {/* <!-- end card --> */}
                    {/* <!-- end row --> */}

                </div> 
                {/* <!-- end col --> */}
            </div>
            {/* <!-- end row --> */}
        </div>
        {/* <!-- end container --> */}
    </div>
    {/* <!-- end page --> */}


    <Footer/>

    {/* <!-- Vendor js --> */}
    <script src="/js/vendor.min.js"></script>

    {/* <!-- App js --> */}
    <script src="/js/app.min.js"></script>

</div>

  );
}



