// api.js
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

export const fetchHospitalServices = (auth, setHospitalServices, requestBody) => {
  const url = 'https://referralapi.tkprotich.com/api/hospital-services/';

  axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.access,
    },
    data: requestBody, // Include the query parameters in the request body
  })
    .then((response) => {
      const retrievedServices = response.data;
      console.log(retrievedServices);
      if (retrievedServices) {
        setHospitalServices(retrievedServices);
      }
    })
    .catch((error) => {
      // Handle error
      console.log(error);
    });
};


export const getServices = (auth, setServices) => {
    // Assuming you have the API endpoint for fetching hospitals
    

    // Fetch hospitals from the API
    axios.get('https://referralapi.tkprotich.com/api/services/', {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + auth.access,
      },



    })
      .then(response => response.data)
      .then(data => {
        // Assuming the API response returns an array of hospital names
        const optionItems = data['results'].map((d) => {
          return { value: d.id, label: d.name };
        });
        setServices(optionItems)
      })
      .catch(error => {
        console.error("Error fetching hospitals:", error);
      });
  }

export const getPatients= (auth, setPatients, setAllPatients, patient_id) => {
    // Assuming you have the API endpoint for fetching hospitals

    const API_END_POINT = patient_id ? `https://referralapi.tkprotich.com/api/patients/?${patient_id}` : 'https://referralapi.tkprotich.com/api/patients/';
    // Fetch hospitals from the API
    axios.get(API_END_POINT, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + auth.access,
      },
    })
      .then(response => response.data)
      .then(data => {
        // Assuming the API response returns an array of hospital names
        const optionItems = data['results'].map((d) => {
          return { value: d.id, label: d.name };
        });
        setPatients(optionItems)
        setAllPatients(data['results'])
      })
      .catch(error => {
        console.error("Error fetching patients:", error);
      });
  }



  
//   Fetch hospital ...........................................................

export const fetchHospitals = (auth, service_id, setHospitals)=>{
  const hospitalsEndpoint = `https://referralapi.tkprotich.com/api/hospital/?service_id=${service_id}`; 
axios.get(hospitalsEndpoint, {
    headers: {
        "Content-Type": "application/json",
      Authorization: "Bearer " + auth.access,
    },
    data: {},
  })
    .then(response => response.data)
    .then(data => {
      // Assuming the API response returns an array of hospital names
      const optionItems = data['results'].map((d) => {
        return { value: d.id, label: d.name };
      });
      setHospitals(optionItems)
    })
    .catch(error => {
      console.error("Error fetching hospitals:", error);
    });
}




export const handleAdmitReferralButtonClick = (referral_id, status, auth) => {
  // Assuming you have the referral ID and the API endpoint URL
const apiUrl = 'https://referralapi.tkprotich.com/api/referrals/' + referral_id + '/';

// Create the data object with the updated status
const data = {
  status: status
};

// Send the POST request
fetch(apiUrl, {
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + auth.access

  },
  body: JSON.stringify(data)
})
  .then(response => {
    if (response.ok) {
      toast.success('Referral status updated successfully.')

      // Handle any further actions or UI updates here
    } else{
      response.text().then(message=>{
        const parsedMessage = JSON.parse(message);
        toast.warn(parsedMessage.message);
      })
      console.log(response.statusText);
    }
  })
  .catch(error => {
    console.log(error.response.data);
    toast.error('Failed.')
    // Handle error cases here
  });
};