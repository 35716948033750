
const servicecolumns = [

    {
        name:'ID',
		selector: row => row.id,
	},
    {
        name: "Name",
		selector: row => row.service_name,
},
        {
            name: "Commmission",
            selector: row => row.commission,
        },       
                {
                    name: "Payment Term",
                    selector: row => row.payment_term,
                },
                {
                    name: "Status",
                    selector: row => row.status,
                }
                
                ];


export default servicecolumns
