import React, { useState, useEffect } from 'react';

function Alert({ message }) {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 5000); // Duration in milliseconds (e.g., 5000ms = 5 seconds)

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showAlert && (
        <div className="row">
          <div className="col-12">
            <div className="alert alert-warning alert-dismissible fade show mb-3" role="alert">
              {message}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Alert;