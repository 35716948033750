import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthProvider";
const PrivateRoute = () => {
  const user = useAuth();
  console.log(user);
  if (!user.access=='') {return <Outlet />; }
  return <Navigate to="/login" />;
};

export default PrivateRoute;