import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode'
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("user") || "");
  const [role, setRole] = useState(localStorage.getItem("role") || "");
  const [access, setAccess] = useState(localStorage.getItem("access") || "");
  const [refresh, setRefresh] = useState(localStorage.getItem("refresh") || "");
  const [roleId, setRoleId] = useState(localStorage.getItem("roleId") || "");
  const [name, setName] = useState(localStorage.getItem("name") || "");
  const navigate = useNavigate();
  const loginAction = async (data) => {
    try {
      const response = await fetch("https://referralapi.tkprotich.com/api/token/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.access) {
        const user_data = jwtDecode(res.access);
        setAccess(res.access);
        setRefresh(res.refresh); 


        setUser(user_data.username);
        setRole(user_data.role);
        setRoleId(user_data.role_id);
        setName(user_data.name);


        localStorage.setItem("access", res.access);
        localStorage.setItem("refresh", res.refresh);

        localStorage.setItem("user", user_data.username);
        localStorage.setItem("role", user_data.role);
        localStorage.setItem("roleId", user_data.role_id);
        localStorage.setItem("name", user_data.name);
        navigate("/");
        return;
      }
      throw new Error(res.message);
    } catch (err) {
      // console.error(err);
    }
  };
  const RefreshToken = async () => {
    try {
      const response = await fetch("https://referralapi.tkprotich.com/api/token/refresh/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Refresh: refresh,
        })

      });
      const res = await response.json();
      if (res.access) {
        const user_data = jwtDecode(res.access); 

        setUser(user_data.username);
        setRole(user_data.role);
        setRoleId(user_data.roleId);

        setAccess(res.access);
        setRefresh(res.refresh);

        localStorage.setItem("refresh", res.refresh);
        localStorage.setItem("access", res.access);

        localStorage.setItem("username", user_data.username);
        localStorage.setItem("role", user_data.role);
        localStorage.setItem("roleId", user_data.role_id);
        
        navigate("/");
        return;
      }
      throw new Error(res.message);
    } catch (err) {
      navigate("/login");
    }
  };

  const logOut = () => {
    setUser(null);
    setAccess("");
    setRefresh("");
    setRole("");
    localStorage.removeItem("access");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ access, refresh, role, roleId, user, name, RefreshToken, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
