import NavBar from '../_partials/NavBar'
import { useAuth } from "../../AuthProvider";
import React, { useState, useEffect } from 'react';
import TopNavBar from './TopNavBar';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { Navigate, Link } from "react-router-dom";
export default function MyPatients() {
  const [patients, setPatients] = useState([]);
  const [columns, setColumns] = useState([]); // State to hold dynamic columns
  const auth = useAuth();
  console.log(auth);
  const columnss = [

    {
        name:'ID',
		selector: row => row.id,
	},
    {
        name: "Name",
		selector: row => row.name,
},
        {
            name: "Phone Number",
            selector: row => row.phone_number,
        },       
                {
                    name: "Address",
                    selector: row => row.address,
                },
                {
                    name: "Date of Birth",
                    selector: row => row.date_of_birth,
                },
                {
                    name: "Gender",
                    selector: row => row.gender,
                },
                {
                    name: "Registration Date",
                    selector: row => row.registration_date,
                },
                {
                    name: "Government ID",
                    selector: row => row.government_id,
                },
                {
                    name: 'Action',
                    cell: (row) => (
                      <Link  className="btn btn-primary waves-effect waves-light rounded-pill" to={`${row.id}`}>View Patient</Link>
              
                    ),
                    ignoreRowClick: true,
                    allowoverflow: true,
                    button: true,
                  }, 
                ];


  useEffect(() => {
    if(auth.access){
    // Assuming API endpoint for fetching patients
    const patientEndpoint = "https://referralapi.tkprotich.com/api/patients/";
    

    axios.get(patientEndpoint, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + auth.access,
      },
    })
      .then((response) => {
        const retrievedPatients = response.data['results'];
        console.log(retrievedPatients);
        if(retrievedPatients.length>0){
        setPatients(retrievedPatients);
        setColumns(columnss); // Generate columns based on 
        }
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      })
  }}, []);

  const generateColumns = (patients = []) => {
    if (patients.length === 0) {
      return [];
    }

    const firstPatient = patients[0];
    return Object.keys(firstPatient).map((key) => ({
      name: key, // Capitalize column names
      selector: row => row.key,
      sortable: true,
    }));
  };
  console.log(columns);
  console.log(patients);

  return (
    <div id="wrapper">
      {/* Header and sidebar content */}
      <NavBar />
      <TopNavBar />

      {/* Page content */}
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <br/>
            
          
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-8">
                                    <form className="d-flex flex-wrap align-items-center">
                                        <label htmlFor="inputPassword2" className="visually-hidden">Search</label>
                                        <div className="me-3">
                                            <input type="search" className="form-control my-1 my-md-0"
                                                id="inputPassword2" placeholder="Search..."/>
                                        </div>
                                        <label htmlFor="status-select" className="me-2">Sort By</label>
                                        <div className="me-sm-3">
                                            <select className="form-select my-1 my-md-0" id="status-select">
                                                <option selected="">All</option>
                                                <option value="1">Name</option>
                                                <option value="2">Post</option>
                                                <option value="3">Followers</option>
                                                <option value="4">Followings</option>
                                            </select>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-md-end mt-3 mt-md-0">
                                        <button type="button"
                                            className="btn btn-success waves-effect waves-light me-1"><i
                                                className="mdi mdi-cog"></i></button>
                                        <a type="button" className="btn btn-danger waves-effect waves-light"
                                            href='/register-patient'><i
                                                className="mdi mdi-plus-circle me-1"></i> Add New Patient</a>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div>
                      <DataTable
                        title="My Patients"
                        columns={columns}
                        data={patients}
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer content */}
        <footer className="footer text-center">
          ...
        </footer>
      </div>
    </div>
  );
}
