import NavBar from '../_partials/NavBar'
import { useAuth } from "../../AuthProvider";
import React, { useState, useEffect } from 'react';
import TopNavBar from './TopNavBar';
import axios from 'axios';
import DataTable from 'react-data-table-component';
export default function ReferralOrders(){
    const [patients, setPatients] = useState([]);
    const auth = useAuth();
    //   useEffect(() => {
    //     $('#datatable-buttons').DataTable();
    // },[patients]);
    useEffect(() => {
        
      // Assuming you have the API endpoint for fetching patients
      const ReferralEndpoint = "https://referralapi.tkprotich.com/api/referrals/";
      axios.get(ReferralEndpoint, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + auth.access
        }
      })
      .then(response => {
  const retrievedPatients = Object.values(response.data);
//   console.log(retrievedPatients[3]);
  setPatients(retrievedPatients[3]);
      })
    .catch(function (error) {
        // handle error
        console.log(error);
        // auth.RefreshToken()
    })
    .finally(function () {
        // always executed
    });
    
    }, []);


    return (
        
    
<div id="wrapper">

{/* <!-- Topbar Start --> */}
<NavBar/>

{/* <!-- end Topbar --> */}

{/* <!-- ========== Left Sidebar Start ========== --> */}

{/* <!-- Left Sidebar End --> */}
{/* <SideBar/> */}
<TopNavBar/>

{/* <!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== --> */}
        {/* // <!-- ============================================================== --> */}
        {/* // <!-- Start Page Content here --> */}
        {/* // <!-- ============================================================== --> */}
    
        <div className="content-page">
            <div className="content">
    
                {/* <!-- Start Content--> */}
                <div className="container-fluid">
    
                    {/* <!-- start page title --> */}
                    
                    {/* <!-- end page title --> */}
    
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
    
                                    <h4 className="header-title">My Referral Orders</h4>
                                    <p className="text-muted font-13 mb-4">
                                       Referral Orders
                                    </p>
    
                                    {patients.length === 0 ? (
                                    <p>No data available</p>
                                    ) :<table id="datatable-buttons" className="table table-striped dt-responsive nowrap w-100">
                                        <thead>
                                            <tr>
                                                <th>#ID</th>
                                                <th>Patient</th>
                                                <th>Referred Hospital</th>
                                                <th>Accepting Hospital</th>
                                                <th>Referral Code</th>
                                                <th>Current Diagnosis</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {patients.map(patient => (
                                            <tr key={patient.id}>
                                            <td>{patient.id}</td>
                                            <td>{patient.patient_name}</td>
                                            <td>{patient.hospital_name}</td>
                                            <td>{patient.accepted_by_name ? patient.accepted_by_name : "Not Yet"} </td>
                                            <td>{patient.referral_code}</td>
                                            <td>{patient.current_diagnosis}</td>
                                            <td>{patient.status}</td>
                                            {/* Render other patient properties in additional table cells */}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>}
    
                                </div>
                                 {/* <!-- end card body--> */}
                            </div>
                            {/* <!-- end card --> */}
                        </div>
                        {/* <!-- end col--> */}
                    </div>
    
                </div>
                 {/* <!-- container --> */}
    
            </div> 
            {/* <!-- content --> */}
    
            {/* <!-- Footer Start --> */}
            <footer className="footer text-center">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <script>document.write(new Date().getFullYear())</script> &copy; Upbit theme by <a href="">Coderthemes</a> 
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- end Footer --> */}
    
        </div>
{/*     
        // <!-- ============================================================== -->
        // <!-- End Page content -->
        // <!-- ============================================================== --> */}</div>
    )
}