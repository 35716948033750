import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthProvider from './AuthProvider';
import Login from './components/Login';
import PrivateRoute from './PrivateRoute';
import Dashboard from './components/Dashboard';
import PatientProfile from './components/PatientProfile';
import ReferralProfile from './components/ReferralProfile';
import Signup from './components/Signup';
import ReferrerRoute from './ReferrerRoute'
import HospitalRoute from './HospitalRoute'
import RegisterPatient from './components/referrer/RegisterPatient';
import RefernewPatient from './components/referrer/RefernewPatient';
import MyPatients from './components/referrer/MyPatients';
import ReferralOrders from './components/referrer/ReferralOrders';
import HospitalReferralOrders from './components/hospital/HospitalReferralOrders';
import Services from './components/hospital/Services';
import HospitalReferralAccepted from './components/hospital/HospitalReferralAccepted'
import ServicesCommisions from './components/hospital/ServicesCommisions'
import HospitalReferralPayouts from './components/hospital/HospitalReferralPayouts'
import MakeReferrerPayout from './components/hospital/MakeReferrerPayout'
import PayoutRequest from './components/referrer/PayoutRequest'
import FAQ from './components/FAQ'
// require('dotenv').config()
function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/faq" element={<FAQ />} />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/my-patients/:patient_id" element={<PatientProfile />} />
              <Route path="/referral-orders/:ids" element={<ReferralProfile />} />
              <Route element={<ReferrerRoute/>}>
              <Route path="/register-patient" element={<RegisterPatient />} />
              <Route path="/refer-patient" element={<RefernewPatient />} />
              <Route path="/my-patients" element={<MyPatients />} />
              <Route path="/referral-orders" element={<ReferralOrders />} />
              <Route path="/payout-request" element={<PayoutRequest />} />
              </Route>
              <Route element={<HospitalRoute/>}>
                <Route path="/services" element={<Services />} />
                <Route path="/referralorders" element={<HospitalReferralOrders />} />
                <Route path="/admitted" element={<HospitalReferralAccepted />} />
                <Route path="/commision" element={<ServicesCommisions />} />
                <Route path="/payouts" element={<HospitalReferralPayouts />} />
                <Route path="/payouts/:referrerId" element={<MakeReferrerPayout />} />
              </Route>
            </Route>
            <Route path="/signup" element={<Signup />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  )}


  export default App;