// import { Feather } from 'react-feather';
const TopNavBar = () => {
    return (
        <div className="topnav">
        <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">

                <div className="collapse navbar-collapse" id="topnav-menu-content">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <i data-feather="home" className="me-1"></i> Dashboards
                            </a>
                        </li>

                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-ui" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i data-feather="briefcase" className="me-1"></i> Patients <div className="arrow-down"></div>
                        </a>

                        <div className="dropdown-menu" aria-labelledby="topnav-ui">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div>
                                        <a href="/my-patients" className="dropdown-item">My Patients</a>
                                        <a href="/payout-request" className="dropdown-item">Payout Request</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-ui" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i data-feather="briefcase" className="me-1"></i> Refer a Patient <div className="arrow-down"></div>
                            </a>

                            <div className="dropdown-menu" aria-labelledby="topnav-ui">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div>
                                            <a href="/refer-patient" className="dropdown-item">Refer Patient</a>
                                            <a href="/referral-orders" className="dropdown-item">Referral orders</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle arrow-none" href="#" id="topnav-apps" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i data-feather="grid" className="me-1"></i> Support <div className="arrow-down"></div>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="topnav-apps">
                                {/* <a href="#" className="dropdown-item">Live Chat</a>
                                <a href="#" className="dropdown-item">Forum</a> */}
                                <a href="/faq" className="dropdown-item">FAQ</a>
                            </div>
                        </li>
                    </ul> 
                    {/* <!-- end navbar--> */}
                </div> 
                {/* <!-- end .collapsed--> */}
            </nav>
        </div> 
        {/* <!-- end container-fluid --> */}
    </div> 
    // <!-- end topnav-->


)}

export default TopNavBar