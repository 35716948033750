import NavBar from '../_partials/NavBar'
// import SideBar from './_partials/SideBar';
import { useAuth } from "../../AuthProvider";
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode'
import HospitalTopNavBar from './HospitalTopNavBar';
import Select from 'react-select';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import servicecolumns from './ServiceColumns';

function ServicesCommisions() {
    const auth = useAuth();

    const user_data = jwtDecode(auth.access); 
    
    
    const [selectedService
        , setSelectedService] = useState();
    const [hospitalservices, setHospitalServices] = useState()
    const [input, setInput] = useState({  
        user: user_data.user_id,
        commission: null,
        payment_term: null,
        service: null
    });

    const [ services, setServices]  = useState();


    const servicesEndpoint ="https://referralapi.tkprotich.com/api/services/";
    const fetchHospitalServices = () => {
        // Assuming API endpoint for fetching patients
    
        axios.get('https://referralapi.tkprotich.com/api/hospital-services/', {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + auth.access,
          },
        })
          .then((response) => {
            const retrievedServices= response.data;
            console.log(retrievedServices);
            if(retrievedServices){
          setHospitalServices(retrievedServices);
            }
          })
          .catch((error) => {
            // Handle error
            console.log(error);
          })
      }


    useEffect(fetchHospitalServices, []);
    const getServices = () => {
        // Assuming you have the API endpoint for fetching hospitals
        
    
        // Fetch hospitals from the API
        axios.get('https://referralapi.tkprotich.com/api/services/', {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + auth.access,
          },
        })
          .then(response => response.data)
          .then(data => {
            // Assuming the API response returns an array of hospital names
            const optionItems = data.map((d) => {
              return { value: d.id, label: d.name };
            });
            setServices(optionItems)
          })
          .catch(error => {
            console.error("Error fetching hospitals:", error);
          });
      }
    
  
    useEffect(getServices, []);

    const navigate = useNavigate();
    const RegisterOrgAction = async (data) => {
    
    try {
      const response = await fetch(`https://referralapi.tkprotich.com/api/hospital-services/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + auth.access
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      console.log(res.status);
      if (res) {
        console.log(res);
        return;
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };
    const handleSubmitEvent = (e) => {
      e.preventDefault();
        if (input.role !== "" && input.name !== "") {
            RegisterOrgAction(input);
            getServices()
            fetchHospitalServices()
            return;
        }
        alert("pleae provide a valid input");
        };
    const handleInput = (e) => {
    
    const { name, value } = e.target;
            setInput((prev) => ({
                    ...prev,
                    [name]: value,
                    }));
                };
    const handleSelectInput = (e) => {
        setInput(prevInput => ({
            ...prevInput,
            ['service']: e.value
        }));
                };
  return (
    
<div id="wrapper">

    {/* <!-- Topbar Start --> */}
    <NavBar/>

    {/* <!-- end Topbar --> */}

    {/* <!-- ========== Left Sidebar Start ========== --> */}

    {/* <!-- Left Sidebar End --> */}
    {/* <SideBar/> */}
    <HospitalTopNavBar/>

    {/* <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== --> */}

<div className="content-page">
                <div className="content">

                    {/* <!-- Start Content--> */}
                    <div className="container-fluid">
                        
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex justify-content-between align-items-center">
                                    <h4 className="page-title">Conntecting Patient to Services</h4>
                                    <ol className="breadcrumb m-0 page-title-right">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Somalia</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="header-title">Commision per Diagnostics</h4>
                    <p className="text-muted font-13 mb-4">
                      ...
                    </p>
                    <div>
                      <DataTable
                        title="Our Services"
                        columns={servicecolumns}
                        data={hospitalservices}
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
                        
                    </div> 
                    {/* <!-- container --> */}

                </div> 
                {/* <!-- content --> */}

                {/* <!-- Footer Start --> */}
                <footer className="footer text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <script>document.write(new Date().getFullYear())</script> &copy; Upbit theme by <a href="">Coderthemes</a> 
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- end Footer --> */}

            </div>
    {/* <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== --> */}


</div>

  );
}

export default ServicesCommisions;
