
import NavBar from '../_partials/NavBar'
// import SideBar from './_partials/SideBar';
import TopNavBar from './TopNavBar';
import ReferrerDashboard from './ReferrerDashboard'
import { useAuth } from "../../AuthProvider";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode'
import Alert from '../_partials/Alert';
import React, { useState, useEffect, useRef} from 'react';

import {getServices, getPatients, fetchHospitals} from '../api'

import Select from 'react-select';

import axios from 'axios';
import { useLocation } from "react-router-dom";

export default function RefernewPatient() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const serializedPatientDataDictionary = searchParams.get("params");
    let selected_patient = JSON.parse(decodeURIComponent(serializedPatientDataDictionary));
    const auth = useAuth();
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState('');
    const formData = new FormData();
    const [hospitals, setHospitals] = useState([]);
    const [file, setFile] = useState(null);
    const [patients, setPatients] = useState([]);
    const [allPatients, setAllPatients] = useState([]);
    const [selectedHospital
        , setSelectedHospital] = useState(null);


    const [services, setServices] = useState([]);
    const [documents, setDocuments] = useState([]);

    // point to select elements
    const selectHospitalRef = useRef();
    const selectServicesRef = useRef();

    const [input, setInput] = useState({
            name: '',
            phone_number: '',
            address: '',
            email: '',
            date_of_birth: '',
            gender: '',
            referral_code: '',
            referrer: auth.roleId,
            note: '',
            history: '',
            current_diagnosis: '',
            government_id: '',
            service: '',
            hospital: null
        });
    
    
        const handleFileChange = (e) => {
            setDocuments(e.target.files)
          };
  const handlePatientSelect = (patient_detail)=>{
    console.log(allPatients);
    let selected_patient = allPatients.filter(patient => patient.id === patient_detail.value)[0];
    for (const key in selected_patient){
        console.log('key');
        console.log(key);
        const value = selected_patient[key];
            setInput((prev) => ({
                ...prev,
                [key]: value,
                }));
    }
    
    console.log(input);
    };

  

    useEffect(() => {
      // Fetch hospitals from the API
      if(selected_patient){
        for (const key in selected_patient){
            console.log('key');
            console.log(key);
            const value = selected_patient[key];
                setInput((prev) => ({
                    ...prev,
                    [key]: value,
                    }));
        }

      }else
      {getPatients(auth, setPatients, setAllPatients, null)}
      getServices(auth, setServices)
      
    }

      , []);


    // .......................Refer patient regis
    
    const RegisterOrgAction = async () => {
        
    Object.entries(input).forEach(([key, value]) => {
      formData.append(key, value);
    });
    documents.forEach((file) => {
        formData.append("documents", file);
      })
    axios.post('https://referralapi.tkprotich.com/api/refer-new-patient/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${auth.access}`, // Replace `token` with your actual token value
      }
    })
      .then(response => {
        // Handle the response
        return response.data;
      })
      .then(newUserData => {
            // Process the newly created user data
            setAlertMessage('Saved!')
            console.log(newUserData);
            resetInputs()
        })
        .catch(error => {
            setAlertMessage(error.toString())
        });
    };

    const handleSubmitEvent = (e) => {
        e.preventDefault();
        if (input.phone_number !== "") {
            RegisterOrgAction();
            return;
        }
        console.log('Please provide a valid input');

        setAlertMessage('Please provide a valid input');
        };
    


    const handleInput = (e) => {
    const { name, value } = e.target;
            setInput((prev) => ({
                    ...prev,
                    [name]: value,
                    }));
                };

    const handleServiceInput = (e) => {
        if(e){
        setInput(prevInput => ({
            ...prevInput,
            ['service']: e.value
        }));
        
        selectHospitalRef.current.clearValue()
            setHospitals([])


            setInput(prevInput => ({
                ...prevInput,
                ['hospital']: ""
            }));

            fetchHospitals (auth, e.value, setHospitals)
}
                };


    const handleHospitalInput = (e) => {
        console.log(e);
        console.log(e.value);
        if(e){
        setInput(prevInput => ({
            ...prevInput,
            ['hospital']: e.map(h => h.value)
        }));}
                };

    const resetInputs = ()=>{
        setInput({
            name: '',
            phone_number: '',
            address: '',
            email: '',
            date_of_birth: '',
            gender: '',
            referral_code: '',
            referrer: auth.roleId,
            note: '',
            history: '',
            current_diagnosis: '',
            government_id: '',
            service: '',
            hospital: null,
          })
          selected_patient = {}
          getPatients(auth, setPatients, setAllPatients, null)
          selectHospitalRef.current.clearValue()
          selectServicesRef.current.clearValue()

    }
    return (
    
<div id="wrapper">

    {/* <!-- Topbar Start --> */}
    <NavBar/>

    {/* <!-- end Topbar --> */}

    {/* <!-- ========== Left Sidebar Start ========== --> */}

    {/* <!-- Left Sidebar End --> */}
    {/* <SideBar/> */}
    <TopNavBar/>

    {/* <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== --> */}

<div className="content-page">
                <div className="content">

                    {/* <!-- Start Content--> */}
                    <div className="container-fluid">
                        
                        {/* <!-- start page title --> */}
                       
            <br/>
            
          
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-8">
                                    <h3>Search  Patient</h3>
                                    <form className="flex-wrap align-items-center">   
                                        <label htmlFor="search-patient" className="visually-hidden">Search Patient</label>
                                        <Select
                                                    id='search-patient'
                                                    name='search-patient'
                                                    onChange={handlePatientSelect}
                                                    options={patients}
                                                    />
                                    </form>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-md-end mt-3 mt-md-0">
                                        <button type="button"
                                            className="btn btn-success waves-effect waves-light me-1"><i
                                                className="mdi mdi-cog"></i></button>
                                        <button 
                                        type="button"
                                         className="btn btn-danger waves-effect waves-light"
                                         onClick={resetInputs}
                                        ><i
                                                className="mdi mdi-plus-circle me-1"></i> Clear Data</button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
                        {/* <!-- end page title -->  */}
                        {alertMessage && <Alert message={alertMessage} />}

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="header-title">Patient Data</h4>
                                        <p className="sub-header">
                                            Become a referrer and earn commission/ 
                                            Register as Hospital for patient referral 
                                        </p>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <form onSubmit={handleSubmitEvent}>
                                                    <div className='row'>
                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="name" className="form-label">Name</label>
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        value={input.name}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="phone_number" className="form-label">Phone Number</label>
                                                    <input
                                                        type="text"
                                                        id="phone_number"
                                                        name="phone_number"
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                        value={input.phone_number}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="address" className="form-label">Address</label>
                                                    <input
                                                        type="text"
                                                        id="address"
                                                        name="address"
                                                        className="form-control"
                                                        placeholder="Address"
                                                        value={input.address}
                                                        onChange={handleInput}
                                                    />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="date_of_birth" className="form-label">Date of Birth</label>
                                                    <input
                                                        type="date"
                                                        id="date_of_birth"
                                                        name="date_of_birth"
                                                        className="form-control"
                                                        placeholder="Date of Birth"
                                                        value={input.date_of_birth}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="gender" className="form-label">Gender</label>
                                                    <input
                                                        type="text"
                                                        id="gender"
                                                        name="gender"
                                                        className="form-control"
                                                        placeholder="Gender"
                                                        value={input.gender}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="government_id" className="form-label">Government ID</label>
                                                    <input
                                                        type="text"
                                                        id="government_id"
                                                        name="government_id"
                                                        className="form-control"
                                                        placeholder="Government ID"
                                                        value={input.government_id}
                                                        onChange={handleInput}
                                                    />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="current_diagnosis" className="form-label">Current Diagnosis</label>
                                                    <input
                                                        type="text"
                                                        id="current_diagnosis"
                                                        name="current_diagnosis"
                                                        className="form-control"
                                                        placeholder="Current Diagnosis"
                                                        value={input.current_diagnosis}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="history" className="form-label">History</label>
                                                    <textarea
                                                        id="history"
                                                        name="history"
                                                        className="form-control"
                                                        placeholder="History"
                                                        value={input.history}
                                                        onChange={handleInput}
                                                    />
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="service" className="form-label">Select Services</label>
                                                    <Select
                                                    id='service'
                                                    ref={selectServicesRef}
                                                    onChange={handleServiceInput}
                                                    options={services}
                                                    />
                                                    </div>


                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="hospital" className="form-label">Select Hospital</label>
                                                    <Select
                                                    id='hospital'
                                                    onChange={handleHospitalInput}
                                                    ref={selectHospitalRef}
                                                    options={hospitals}
                                                    isMulti
                                                    />
                                                    </div>
                                                    
                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="note" className="form-label">Note</label>
                                                    <textarea
                                                        id="note"
                                                        name="note"
                                                        className="form-control"
                                                        placeholder="note"
                                                        value={input.note}
                                                        onChange={handleInput}
                                                    />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                    <label htmlFor="documents" className="form-label">Documents</label>
                                                        
                                                        <input
                                                        id='documents'
                                                            type="file"
                                                            className="form-control"
                                                            multiple
                                                            onChange={handleFileChange}
                                                        />
                                                    </div>

                                                        
                                                    <div className="row">
                                                <div className="col-md-12 mb-3 d-flex justify-content-end">
                                                    <button className="btn btn-primary" type="submit">Submit</button>
                                                </div>
                                                </div>
                                                    </div>
                                                </form>
                                            </div> 
                                            {/* <!-- end col --> */}
                                        </div>
                                        {/* <!-- end row--> */}

                                    </div>
                                    {/* <!-- end card-body --> */}
                                </div> 
                                {/* <!-- end card --> */}
                            </div>
                            {/* <!-- end col --> */}
                        </div>
                        {/* <!-- end row --> */}
                        
                    </div> 
                    {/* <!-- container --> */}

                </div> 
                {/* <!-- content --> */}

                {/* <!-- Footer Start --> */}
                <footer className="footer text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <script>document.write(new Date().getFullYear())</script> &copy; Upbit theme by <a href="">Coderthemes</a> 
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- end Footer --> */}

            </div>
    {/* <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== --> */}


</div>

    );
}