import NavBar from './_partials/NavBar'
// import SideBar from './_partials/SideBar';
import TopNavBar from './_partials/TopNavBar';
import HospitalDashboard from './hospital/HospitalDashboard'
import ReferrerDashboard from './referrer/ReferrerDashboard'
import { useAuth } from "../AuthProvider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode'
function Dashboard() {
    const user = useAuth();
    

    const user_data = jwtDecode(user.access); 
    
    const [input, setInput] = useState({
        user: user_data.user_id,
        name: "",
        role: "",
        contact: "",
        website: null,
        services: null,
        emails: null,
    });
  
    const navigate = useNavigate();
    const RegisterOrgAction = async (data) => {
    
    try {
      const response = await fetch(`https://referralapi.tkprotich.com/api/${input.role}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.status==201) {
        localStorage.setItem("role", input.role);
        navigate(`/${input.role}`);
        return;
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };
    const handleSubmitEvent = (e) => {
      e.preventDefault();
        if (input.role !== "" && input.name !== "") {
            RegisterOrgAction(input);
            return;
        }
        alert("pleae provide a valid input");
        };
    const handleInput = (e) => {
    
    const { name, value } = e.target;
            setInput((prev) => ({
                    ...prev,
                    [name]: value,
                    }));
                };
if(user.role=='hospital'){
return(
    <HospitalDashboard/>
)
    }
    if(user.role=='referrer'){
return (
    <ReferrerDashboard/>
)
    }
  return (
    
<div id="wrapper">

    {/* <!-- Topbar Start --> */}
    <NavBar/>

    {/* <!-- end Topbar --> */}

    {/* <!-- ========== Left Sidebar Start ========== --> */}

    {/* <!-- Left Sidebar End --> */}
    {/* <SideBar/> */}
    <TopNavBar/>

    {/* <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== --> */}

<div className="content-page">
                <div className="content">

                    {/* <!-- Start Content--> */}
                    <div className="container-fluid">
                        
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex justify-content-between align-items-center">
                                    <h4 className="page-title">Conntecting Patient to Services</h4>
                                    <ol className="breadcrumb m-0 page-title-right">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Somalia</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end page title -->  */}
                        

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="header-title">Input Types</h4>
                                        <p className="sub-header">
                                            Become a referrer and earn commission/ 
                                            Register as Hospital for patient referral 
                                        </p>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <form onSubmit={handleSubmitEvent}>
                                                    <div className='row'>
                                                        
                                                        <div class="mb-3 col-md-6">
                                                            <label for="inputState" class="form-label">Choose Role</label>
                                                            <select id="inputState" name='role' class="form-select" onChange={handleInput}>
                                                                <option>Choose</option>
                                                                <option value={'hospital'}>Hospital</option>
                                                                <option value={'referrer'}>Referral</option>
                                                            </select>
                                                        </div>

                                                        <div className="col-md-6  mb-3">
                                                            <label htmlFor="name" className="form-label">Organization Name</label>
                                                            <input type="text" id="name" name="name"
                                                                className="form-control" placeholder="organization name" onChange={handleInput}/>
                                                        </div>
                                                        <div className="col-md-6  mb-3">
                                                            <label htmlFor="contact" className="form-label">Contact Number</label>
                                                            <input type="text" id="contact_number" name="contact"
                                                                className="form-control" placeholder="contact" onChange={handleInput}/>
                                                        </div>
                                                        <div className="col-md-12  mb-3">
                                                        <button className="btn btn-primary" type="submit"> Log In </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div> 
                                            {/* <!-- end col --> */}
                                        </div>
                                        {/* <!-- end row--> */}

                                    </div>
                                    {/* <!-- end card-body --> */}
                                </div> 
                                {/* <!-- end card --> */}
                            </div>
                            {/* <!-- end col --> */}
                        </div>
                        {/* <!-- end row --> */}
                        
                    </div> 
                    {/* <!-- container --> */}

                </div> 
                {/* <!-- content --> */}

                {/* <!-- Footer Start --> */}
                <footer className="footer text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <script>document.write(new Date().getFullYear())</script> &copy; Upbit theme by <a href="">Coderthemes</a> 
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- end Footer --> */}

            </div>
    {/* <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== --> */}


</div>

  );
}

export default Dashboard;
