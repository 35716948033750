import { useState } from "react";
import { useAuth } from "../AuthProvider";
export default function Login() {
    const [input, setInput] = useState({
      username: "",
      password: "",
    });
  
    const auth = useAuth();
    const handleSubmitEvent = (e) => {
      e.preventDefault();
      if (input.username !== "" && input.password !== "") {
        auth.loginAction(input);
        return;
      }
      alert("pleae provide a valid input");
    };
  const handleInput = (e) => {
    
    const { name, value } = e.target;
    
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="loading authentication-bg authentication-bg-pattern">

   

    <div className="account-pages mt-5 mb-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6 col-xl-4">


                    <div className="card bg-pattern">

                        <div className="card-body p-4">

                            <div className="text-center w-75 m-auto">
                                <div className="auth-logo">
                                    <a href="#" className="logo logo-dark text-center">
                                        <span className="logo-lg">
                                            <img src="/images/logo-dark.png" alt="" height="28"/>
                                        </span>
                                    </a>

                                    <a href="#" className="logo logo-light text-center">
                                        <span className="logo-lg">
                                            <img src="/images/logo-light.png" alt="" height="28"/>
                                        </span>
                                    </a>
                                </div>
                                <p className="text-muted mb-4 mt-3">Enter your email address/username/phone number and password to access admin
                                    panel.</p>
        <p className="">Don't have an account? <a href="/signup"
                className="text-decoration-underline"><b>Sign Up</b></a></p>
                            </div>

                            <form onSubmit={handleSubmitEvent}>

                                <div className="mb-3">
                                    <label for="username" className="form-label">Phone Number/Username</label>
                                    <input className="form-control" type="text" name="username" id="username" required=""
                                        placeholder="Enter your username" 
                                        onChange={handleInput}/>
                                </div>

                                <div className="mb-3">
                                    <label for="password" className="form-label">Password</label>
                                    <div className="input-group input-group-merge">
                                        <input type="password" id="password" className="form-control"
                                            placeholder="Enter your password" name="password"
                                            onChange={handleInput}/>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="checkbox-signin" checked/>
                                        <label className="form-check-label" for="checkbox-signin">Remember me</label>
                                    </div>
                                </div>

                                <div className="text-center d-grid">
                                    <button className="btn btn-primary" type="submit"> Log In </button>
                                </div>

                            </form>
                            
                            

                            <div className="row mt-3">
                                <div className="col-12 text-center">
                                    <p> <a href="auth-recoverpw.html">Forgot your password?</a></p>
                                    <p className="text-white-50">Don't have an account? <a href="auth-register.html"
                                            className="text-white ms-1 text-decoration-underline"><b>Sign Up</b></a></p>
                                </div> 
                                {/* <!-- end col --> */}
                            </div>

                            <div className="text-center">
                                <h5 className="mt-3 text-muted">Sign in with</h5>
                                <ul className="social-list list-inline mt-3 mb-0">
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);"
                                            className="social-list-item border-primary text-primary"><i
                                                className="mdi mdi-facebook"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);"
                                            className="social-list-item border-danger text-danger"><i
                                                className="mdi mdi-google"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-info text-info"><i
                                                className="mdi mdi-twitter"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);"
                                            className="social-list-item border-secondary text-secondary"><i
                                                className="mdi mdi-github"></i></a>
                                    </li>
                                </ul>
                            </div>

                        </div> 
                        {/* <!-- end card-body --> */}
                    </div>
                    {/* <!-- end card --> */}

                    <div className="row mt-3">
                        <div className="col-12 text-center">
                            <p> <a href="auth-recoverpw.html" className="text-white-50 ms-1">Forgot your password?</a></p>
                            <p className="text-white-50">Don't have an account? <a href="auth-register.html"
                                    className="text-white ms-1 text-decoration-underline"><b>Sign Up</b></a></p>
                        </div> 
                        {/* <!-- end col --> */}
                    </div>
                    {/* <!-- end row --> */}

                </div> 
                {/* <!-- end col --> */}
            </div>
            {/* <!-- end row --> */}
        </div>
        {/* <!-- end container --> */}
    </div>
    {/* <!-- end page --> */}


    <footer className="footer footer-alt text-white-50">
        <script>document.write(new Date().getFullYear())</script> &copy; Upbit theme by <a href=""
            className="text-white-50">Coderthemes</a>
    </footer>

    {/* <!-- Vendor js --> */}
    <script src="/js/vendor.min.js"></script>

    {/* <!-- App js --> */}
    <script src="/js/app.min.js"></script>

</div>

  );
}



