import NavBar from './_partials/NavBar'
// import SideBar from './_partials/SideBar';
import TopNavBar from './referrer/TopNavBar';
import HospitalTopNavBar from './hospital/HospitalTopNavBar';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import Footer from './_partials/Footer';
import DataTable from 'react-data-table-component';
import { HandleDownload } from './DocumentsDownload';
function PatientProfile() {
    const auth = useAuth();
    const navigate = useNavigate();
    const { patient_id } = useParams();
    const getPatient_url = `https://referralapi.tkprotich.com/api/patients/${patient_id}/`

  const [patientdata, setPatientData] = useState([])
  const [referrals, setReferrals] = useState([])
  const serializedPatientData = encodeURIComponent(JSON.stringify(patientdata));
  useEffect(() => {
    // Assuming API endpoint for fetching patients

    axios.get(getPatient_url, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + auth.access,
      },
    })
      .then((response) => {
        const response_data = response.data;
        console.log(response_data);
        setPatientData(response_data);
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      })
  }, []);


  useEffect(() => {
        
    // Assuming you have the API endpoint for fetching patients
    const ReferralEndpoint = `https://referralapi.tkprotich.com/api/referrals/?patient_id=${patient_id}`;
    axios.get(ReferralEndpoint, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + auth.access
      }
    })
    .then(response => {
const retrievedReferrals = response.data['results'];
console.log(retrievedReferrals);
setReferrals(retrievedReferrals);
    })
  .catch(function (error) {
      // handle error
      console.log(error);
      // auth.RefreshToken()
  })
  .finally(function () {
      // always executed
  });
  
  }, []);

  




//   columns
const columns = [

    {
        name:'ID',
    selector: row => row.id,
        sortable: true,
  },
    {
        name: "Name",
    selector: row => row.patient_name,
    },
    {
        name: "Referrer",
        selector: row => row.referrer_name,
    },       
    {
        name: "Diagnosis",
        selector: row => row.current_diagnosis,
    },
    {
        name: "Date Referral",
        selector: row => row.referral_date,
    },
    {
        name: "Status",
        selector: row => row.status,
    },
    {
        name: 'Action',
        cell: (row) => (
            <button type="button"
                                                    class="btn btn-outline-success waves-effect waves-light"
                                                    onClick={() => HandleDownload(`${row.id}`, auth)}
                                                    >Download-{row.documents_count}
                                                    </button>
            
        ),
        ignoreRowClick: true,
        allowoverflow: true,
        button: true,
      },
    ];



    const handleReferPatient = ()=>{
        navigate(`/refer-patient/?params=${serializedPatientData}`)
    }
  return (
    
<div id="wrapper">

    {/* <!-- Topbar Start --> */}
    <NavBar/>

    {/* <!-- end Topbar --> */}

    {/* <!-- ========== Left Sidebar Start ========== --> */}

    {/* <!-- Left Sidebar End --> */}
    {/* <SideBar/> */}
    {auth.role === "referrer" ? <TopNavBar/>: auth.role==='hospital' ? <HospitalTopNavBar/>: navigate('/')}

    {/* <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== --> */}
 <div className="content-page">
        <div className="content">

            {/* <!-- Start Content--> */}
            <div className="container-fluid">

                {/* <!-- start page title -->
                
                <!-- end page title --> */}
            <br/>
            
          
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="text-md-end mt-3 mt-md-0">
                                        <button type="button"
                                            className="btn btn-success waves-effect waves-light me-1"><i
                                                className="mdi mdi-cog"></i></button>
                                        <button type="button" className="btn btn-danger waves-effect waves-light"
                                           onClick={handleReferPatient}><i
                                                className="mdi mdi-plus-circle me-1"></i> Refer Patient</button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>

                <div className="row">
                    <div className="col-lg-3 col-xl-3">
                        <div className="card text-center">
                            <div className="card-body">
                                <img src="/images/users/user-2.jpg" className="rounded-circle avatar-xl img-thumbnail" alt="profile-image" />
                
                                <h4 className="mb-1 mt-2">{patientdata.name}</h4>
                                <p className="text-muted">@{patientdata.name}</p>
                                <div className="text-start mt-3">
                                    <h4 className="font-13 text-uppercase">About Patient :</h4>
                                    <p className="text-muted font-13 mb-3">
                                        <span className="fw-semibold">History :</span>
                                        <span className="ms-2">{patientdata.history}</span>
                                       
                                    </p>
                                    <p className="text-muted mb-2 font-13">
                                        <span className="fw-semibold">Full Name :</span>
                                        <span className="ms-2">{patientdata.name}</span>
                                    </p>
                
                                    <p className="text-muted mb-2 font-13"><span className="fw-semibold">Mobile :</span><span className="ms-2">{patientdata.phone_number}</span></p>
                
                                    <p className="text-muted mb-2 font-13"><span className="fw-semibold">Email :</span> <span className="ms-2">{patientdata.email}</span></p>
                
                                    <p className="text-muted mb-1 font-13"><span className="fw-semibold">Location :</span> <span className="ms-2">{patientdata.address}</span></p>
                                </div>
                
                                <ul className="social-list list-inline mt-3 mb-0">
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-primary text-primary"><i className="mdi mdi-facebook"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-danger text-danger"><i className="mdi mdi-google"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-info text-info"><i className="mdi mdi-twitter"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-secondary text-secondary"><i className="mdi mdi-github"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- end card --> */}
                        {/* <!-- end card--> */}
                    </div>
                    {/* <!-- end col--> */}
                
                    <div className="col-lg-9 col-xl-9">
                        <div className="card">
                            <div className="card-body">
                            {referrals.length === 0 ? (
                            <p>No data available</p>
                            ) :
                            <DataTable
                                title="Referrals"
                                columns={columns}
                                data={referrals}
                                pagination
                            />}
                            </div>
                        </div>
                        {/* <!-- end card--> */}
                    </div>
                    {/* <!-- end col --> */}
                </div>                
                {/* <!-- end row--> */}

            </div>
             {/* <!-- container --> */}

        </div> 
        {/* <!-- content --> */}

        {/* <!-- Footer Start --> */}
      <Footer/>
        {/* <!-- end Footer --> */}

    </div>

    {/* <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== --> */}


</div>

  );
}

export default PatientProfile;
