

import { useAuth } from "../../AuthProvider";
export default function NavBar() {
    const user = useAuth();
    return (
   
    <div className="navbar-custom">
        <div className="container-fluid">
            <ul className="list-unstyled topnav-menu float-end mb-0">

                <li className="d-none d-lg-block">
                    <form className="app-search">
                        <div className="app-search-box dropdown">
                            <div className="input-group">
                                <input type="search" className="form-control" placeholder="Search..." id="top-search"/>
                                <button className="btn input-group-text" type="submit">
                                    <i data-feather="search" className="icons-xs"></i>
                                </button>
                            </div>
                            <div className="dropdown-menu dropdown-lg" id="search-dropdown">
                                {/* <!-- item--> */}
                                <div className="dropdown-header noti-title">
                                    <h5 className="text-overflow my-2">Found 17 results</h5>
                                </div>
            
                                {/* <!-- item--> */}
                                <a href="#" className="dropdown-item notify-item">
                                    <i data-feather="file-text" className="me-1 icons-xs"></i>
                                    <span>Analytics Report</span>
                                </a>
            
                                {/* <!-- item--> */}
                                <a href="#" className="dropdown-item notify-item">
                                    <i data-feather="life-buoy" className="me-1 icons-xs"></i>
                                    <span>How can I help you?</span>
                                </a>
                            
                                {/* <!-- item--> */}
                                <a href="#" className="dropdown-item notify-item">
                                    <i data-feather="settings" className="me-1 icons-xs"></i>
                                    <span>User profile settings</span>
                                </a>

                                {/* <!-- item--> */}
                                <div className="dropdown-header noti-title">
                                    <h6 className="text-overflow my-2 text-uppercase fw-bold">Users</h6>
                                </div>

                                <div className="notification-list">
                                    {/* <!-- item--> */}
                                    <a href="#" className="dropdown-item notify-item">
                                        <div className="d-flex align-items-start">
                                            <img className="d-flex me-2 rounded-circle" src="/images/users/user-2.jpg" alt="Generic placeholder image" height="32"/>
                                            <div className="w-100">
                                                <h5 className="m-0 font-14">Erwin E. Brown</h5>
                                                <span className="font-12 mb-0">UI Designer</span>
                                            </div>
                                        </div>
                                    </a>

                                    {/* <!-- item--> */}
                                    <a href="#" className="dropdown-item notify-item">
                                        <div className="d-flex align-items-start">
                                            <img className="d-flex me-2 rounded-circle" src="/images/users/user-5.jpg" alt="Generic placeholder image" height="32"/>
                                            <div className="w-100">
                                                <h5 className="m-0 font-14">Jacob Deo</h5>
                                                <span className="font-12 mb-0">Developer</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
            
                            </div>  
                        </div>
                    </form>
                </li>
    
                <li className="dropdown d-inline-block d-lg-none">
                    <a className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                        <i data-feather="search" className="noti-icon"></i>
                    </a>
                    <div className="dropdown-menu dropdown-lg dropdown-menu-end p-0">
                        <form className="p-3">
                            <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username"/>
                        </form>
                    </div>
                </li>
    
            
                <li className="dropdown notification-list topbar-dropdown">
                    <a className="nav-link dropdown-toggle nav-user me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                        <img src="/images/users/user-2.jpg" alt="user-image" className="rounded-circle"/>
                        <span className="pro-user-name ms-1">
                        {user.user} <i className="mdi mdi-chevron-down"></i> 
                        </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                        {/* <!-- item--> */}
                        <div className="dropdown-header noti-title">
                            <h6 className="text-overflow m-0">Name: {user.user}</h6>
                            <div className="dropdown-divider"></div>
                            <h6 className="text-overflow m-0">Role: {user.role==="referrer"? "Referrer":user.role==="hospital"? "Hospital":"" } </h6>
                        </div>
                        <div className="dropdown-divider"></div>
    
    
                        {/* <!-- item--> */}
                        <a href="#" onClick={user.logOut} className="dropdown-item notify-item">
                            <i data-feather="log-out" className="icons-xs me-1"></i>
                            <span>Logout</span>
                        </a>
    
                    </div>
                </li>
    
                <li className="dropdown notification-list">
                    <a href="#" className="nav-link right-bar-toggle">
                        <i data-feather="settings" className="noti-icon"></i>
                    </a>
                </li>
    
            </ul>
    
            {/* <!-- LOGO --> */}
            <div className="logo-box">
                <a href="/" className="logo logo-dark">
                    <span className="logo-sm text-center">
                        <img src="/images/logo-sm.png" alt="" height="24"/>
                        {/* <!-- <span className="logo-lg-text-light">Upbit</span> --> */}
                    </span>
                    <span className="logo-lg">
                        <img src="/images/logo-dark.png" alt="" height="26"/>
                        {/* <!-- <span className="logo-lg-text-light">U</span> --> */}
                    </span>
                </a>
    
                <a href="/" className="logo logo-light">
                    <span className="logo-sm text-center">
                        <img src="/images/logo-sm.png" alt="" height="24"/>
                    </span>
                    <span className="logo-lg">
                        <img src="/images/logo-light.png" alt="" height="26"/>
                    </span>
                </a>
            </div>
    
            <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                <li>
                    <button className="button-menu-mobile me-2">
                        <i className="mdi mdi-menu"></i>
                    </button>
                </li>

                <li>
                    {/* <!-- Mobile menu toggle (Horizontal Layout)--> */}
                    <a className="navbar-toggle nav-link" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>
                    {/* <!-- End mobile menu toggle--> */}
                </li>   
            
                <li>
                    <h5 className="topbar-title">Dashboard</h5>
                    
                </li>
            </ul>
            <div className="clearfix"></div>
        </div>
    </div>

)}