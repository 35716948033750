// import { Feather } from 'react-feather';
const TopNavBar = () =>  {
    return (
        <div className="topnav">
        <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">

                <div className="collapse navbar-collapse" id="topnav-menu-content">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <i data-feather="home" className="me-1"></i> Dashboards
                            </a>
                        </li>
                    </ul> 
                    {/* <!-- end navbar--> */}
                </div> 
                {/* <!-- end .collapsed--> */}
            </nav>
        </div> 
        {/* <!-- end container-fluid --> */}
    </div> 
    // <!-- end topnav-->


)}


export default TopNavBar